import { CasterModel } from "../types/CasterModel";
import { DashboardModel } from "../types/DashboardModel";
import { TransactionModel } from "../types/TransactionModel";

let getCurrentDashboards = async (): Promise<DashboardModel[]> => {
  let token = window.localStorage.getItem("auth");
  return new Promise(async (resolve, reject) => {
    let res: Response | void = await fetch('/api/dashboards', {headers: {Authorization: `${token}`}}).catch((e) => {
      console.error(e);
      return reject();
    });
    if (!res) return reject();
    let data = await res.json();
    return resolve(data);
  });
};

export async function getDashboardInfo(id: string): Promise<DashboardModel> {
  let token = window.localStorage.getItem("auth");
  return new Promise(async (resolve, reject) => {
    let res: Response | void = await fetch(`/api/dashboard/${id}`, {headers: {Authorization: `${token}`}}).catch((e) => {
      console.error(e);
      return reject();
    });
    if (!res) return reject();
    let data = await res.json();
    return resolve(data);
  });
};

let addDashboard = async (dash: DashboardModel): Promise<void> => {
  let token = window.localStorage.getItem("auth");
  return new Promise(async (resolve, reject) => {
    let res: Response | void = await fetch('/api/add-dashboard', { method: 'post', headers: {Authorization: `${token}`, 'Content-Type': 'application/json'}, body: JSON.stringify(dash)}).catch((e) => {
      console.error(e);
      return reject();
    });
    if (!res) return reject();
    if (res.status === 200) return resolve();
    else {
      return reject();
    }
  });
};

let uploadDashboardCover = async (id: string, file: File): Promise<void> => {
  let token = window.localStorage.getItem("auth");

  let formData = new FormData();
  formData.append('file', file);

  const options = {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `${token}`,
    }
  }

  return new Promise(async (resolve, reject) => {
    await fetch(`/api/image-upload/${id}`, options).catch((e) => {
      return reject(e);
    });
    return resolve();
  });
};

let deleteDashboard = async (id: string): Promise<void> => {
  let token = window.localStorage.getItem("auth");
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `${token}`,
    }
  }

  return new Promise(async (resolve, reject) => {
    await fetch(`/api/dashboards/${id}`, options).catch((e) => {
      return reject(e);
    });
    return resolve();
  });
}

let editDashboards = async (body: DashboardModel[]): Promise<void> => {
  let token = window.localStorage.getItem("auth");
  const options = {
    method: 'POST',
    body: JSON.stringify({
      edits: body
    }),
    headers: {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
  }

  return new Promise(async (resolve, reject) => {
    await fetch(`/api/edit-dashboards`, options).catch((e) => {
      return reject(e);
    });
    return resolve();
  });
}

export function expenseEnabled(id: string): Promise<boolean> {
  let token = window.localStorage.getItem("auth");
  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
  }

  return new Promise(async (resolve, reject) => {
    let res = await fetch(`/api/expenses/${id}/checkenabled`, options).catch((err) => {
      console.error(err);
      return reject(err);
    });

    let data = (res as any).text();
    if (await data === 'true') {
      return resolve(true);
    }
    else {
      return resolve(false);
    }
  });
}

export function submitExpense(transaction: TransactionModel, eventId: string): Promise<number> {
  let token = window.localStorage.getItem("auth");
  return new Promise(async (resolve, reject) => {
    let res: Response | void = await fetch(`/api/expenses/${eventId}/add-expense`, { method: 'post', headers: {Authorization: `${token}`, 'Content-Type': 'application/json'}, body: JSON.stringify(transaction)}).catch((e) => {
      console.error(e);
      return reject();
    });

    let body = await res?.text();
    
    if (res && res.status === 200 && body) {
      if (!isNaN(parseInt(body))) {
        return resolve(parseInt(body));
      }
    }
    
    return reject();

  });
}

export function uploadTransactionDocument(id: number, file: File): Promise<void> {
  let token = window.localStorage.getItem("auth");

  let formData = new FormData();
  formData.append('file', file);

  const options = {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `${token}`,
    }
  }

  return new Promise(async (resolve, reject) => {
    let res = await fetch(`/api/expenses/doc-upload/${id}`, options).catch((e) => {
      return reject(e);
    });

    if (res && res.status === 200) {
      return resolve();
    }

    else {
      return reject();
    }
  });
};

export function getExpenses(id: string): Promise<any> {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`,
    }
  }

  return new Promise(async (resolve, reject) => {
    let res: Response | void = await fetch(`/api/expenses/${id}/fetch`, options).catch((e) => {
      return reject(e);
    });

    if (res && res.status === 200) {
      let data: any = await res.json();
      if (data) {
        return resolve(data);
      }      
    }

    return reject();
    
  });
};

export function deleteExpense(id: string): Promise<void> {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `${token}`,
    }
  }

  return new Promise(async (resolve, reject) => {
    let res = await fetch(`/api/expenses/delete/${id}`, options).catch((e) => {
      return reject(e);
    });

    if (res && res.status === 200) {
      return resolve();
    }

    else {
      return reject();
    }
  });
}

export function getDiscordToken(code: string): Promise<void> {
  return new Promise(async (resolve, reject) => {
    let res: Response | void = await fetch(`/api/discord-auth/${code}`).catch((e) => {
      return reject(e);
    });

    if (res) {
      let data = await res.text();

      if (data && data.length > 0 && !data.toLowerCase().includes('error')) {
        window.localStorage.setItem("discordAuth", data);
        return resolve();
      }
    }

    return reject();
  })
}

export function getCasterInfoFromToken(): Promise<CasterModel> {
  let token = window.localStorage.getItem("discordAuth");

  return new Promise(async (resolve, reject) => {
    if (!token) return reject("No token provided.");

    fetch(`/api/caster-info/discord-token`, {headers: {Authorization: token}}).then(async (res) => {
      if (res) {
        return resolve(await res.json());
      }
      else {
        return reject("An unknown error occurred.")
      }
    })
  });
}

export function checkDiscordToken(): Promise<boolean> {
  let token = window.localStorage.getItem("discordAuth");  

  return new Promise(async (resolve, reject) => {
    if (!token) return resolve(false);

    fetch(`/api/discord-validate`, {headers: {Authorization: token}}).then((res) => {
      console.error(res)
      if (res && res.status !== 500 && res.status !== 401) {
        return resolve(true);
      }
      else {
        return resolve(false);
      }
    }).catch((e) => {
      console.error(e);
      return resolve(false);
    });
  });
}

export function submitNewCaster(p: CasterModel): Promise<void> {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem("discordAuth");

    const options = {
      method: 'POST',
      body: JSON.stringify(p),
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      }
    }

    await fetch(`/api/add-caster`, options).then((res) => {
      if (res.status === 200) return resolve();
      return reject();
    }).catch((e) => {
      console.error(e);
      return reject();
    });

  });
}

export function editCaster(p: CasterModel): Promise<void> {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem("discordAuth");

    const options = {
      method: 'POST',
      body: JSON.stringify(p),
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      }
    }

    await fetch(`/api/edit-caster`, options).then((res) => {
      if (res.status === 200) return resolve();
      return reject();
    }).catch((e) => {
      console.error(e);
      return reject();
    });

  });
}

export function deleteCasterProfile(): Promise<void> {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem("discordAuth");

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `${token}`,
      }
    }

    await fetch(`/api/delete-my-caster-profile`, options).then((res) => {
      if (res.status === 200) return resolve();
      return reject();
    }).catch((e) => {
      console.error(e);
      return reject();
    });

  });
}

export function getAllCasters(): Promise<CasterModel[]> {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem("auth");

    let res: Response | void = await fetch('/api/admin/get-casters', {headers: {Authorization: `${token}`}}).catch((e) => {
      return reject(e);
    });

    if (res && res.status === 200) {
      let data: any = await res.json();
      if (data) {
        return resolve(data);
      }
    }

    return reject();
  })
}

export function getCasterById(id: string): Promise<CasterModel> {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem("auth");

    let res: Response | void = await fetch(`/api/admin/get-caster/${id}`, {headers: {Authorization: `${token}`}}).catch((e) => {
      return reject(e);
    });

    if (res && res.status === 200) {
      let data: any = await res.json();
      if (data) {
        return resolve(data);
      }
    }

    return reject();
  });
}

export function adminEditCaster(p: CasterModel): Promise<void> {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem("auth");

    const options = {
      method: 'POST',
      body: JSON.stringify(p),
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      }
    }

    await fetch(`/api/admin/update-caster/${p.id}`, options).then((res) => {
      if (res.status === 200) return resolve();
      return reject();
    }).catch((e) => {
      console.error(e);
      return reject();
    });

  });
}

export function adminDeleteCasterProfile(id: number): Promise<void> {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem("auth");

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `${token}`,
      }
    }

    await fetch(`/api/admin/delete-caster/${id}`, options).then((res) => {
      if (res.status === 200) return resolve();
      return reject();
    }).catch((e) => {
      console.error(e);
      return reject();
    });

  });
}

export async function getAllAssignments() {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`,
    }
  }

  let res: Response | void = await fetch(`/api/admin/caster/all-assignments`, options).catch((e) => { Promise.reject(e) });

  if (!res) return Promise.reject();

  if (res.status === 200) {
    let data: any = await res.json();
    if (data) {
      return data as {[key: number]: string[]};
    }
  }
  else {
    return Promise.reject();
  }

}

export async function updateCasterAssignments(type: 'event' | 'caster', target: string | number, newVals: string[] | number[]) {
  let token = window.localStorage.getItem("auth");

  let body = {};
  if (type === 'event') {
    body = {
      event: target,
      casters: newVals
    }
  }

  else {
    body = {
      caster: target,
      events: newVals
    }
  }

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
  }

  await fetch(`/api/admin/caster/update-assignments/${type}`, options).catch((e) => { return Promise.reject(e) });
  return;
}

export async function getAirlines() {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`
    }
  };

  let res = await fetch(`/api/travel/airlines`, options).catch(() => { return undefined });
  let data = await res?.json();

  if (!data) return Promise.reject();
  return data;
}

export async function getAirports() {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`
    }
  };

  let res = await fetch(`/api/travel/airports`, options).catch(() => { return undefined });
  let data = await res?.json();

  if (!data) return Promise.reject();
  return data;
}

/**
 * NODECG ROUTES
 */

export async function checkNodeCGOnline(id: string) {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`
    }
  };

  let res = await fetch(`/api/nodecg/${id}/online`, options).catch(() => { return undefined });
  let data = await res?.text();

  if (!data || data !== "true") return Promise.reject();
  return data;
}

export async function getNodeCGPanels(id: string) {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`
    }
  };

  let res = await fetch(`/api/nodecg/${id}/panels`, options).catch(() => { return undefined });
  let data = await res?.json();

  if (!data) Promise.reject();
  return data;
}

export async function getNodeCGGraphics(id: string) {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`
    }
  };

  let res = await fetch(`/api/nodecg/${id}/graphics`, options).catch(() => { return undefined });
  let data = await res?.json();

  if (!data) {
    return Promise.reject();
  }
  else {
    return data;
  }
}

export async function getNodeCGKey(id: string, type: 'graphicsKey' | 'apiKey') {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`
    }
  };

  let paramType = type === 'graphicsKey' ? 'GRAPHICS' : 'API';

  let res = await fetch(`/api/nodecg/${id}/get-key/${paramType}`, options).catch(() => { return undefined });
  let data = await res?.text();

  if (!data) return Promise.reject();
  return data;
}

export async function refreshNodeCGKey(id: string, type: 'graphicsKey' | 'apiKey') {
  let token = window.localStorage.getItem("auth");

  const options = {
    method: 'GET',
    headers: {
      Authorization: `${token}`
    }
  };

  let paramType = type === 'graphicsKey' ? 'GRAPHICS' : 'API';

  let res = await fetch(`/api/nodecg/${id}/refresh-key/${paramType}`, options).catch(() => { return undefined });
  let data = await res?.text();

  if (!data) return Promise.reject();
  return data;
}

export async function getTwitchOptions(): Promise<{channel_name: string, id: number}[]> {
  let token = window.localStorage.getItem("auth");

  return await (await fetch(`/api/twitch-channels`, {headers: {Authorization: token ?? ''}})).json().catch(() => {
    return [];
  });
}

export { getCurrentDashboards, addDashboard, uploadDashboardCover, deleteDashboard, editDashboards };
