import { createTheme, responsiveFontSizes } from '@mui/material';

let mainTheme = createTheme({
    palette: {
      primary: {
        main: '#244630',
      },
      secondary: {
        main: '#ff9528',
      },
      error: {
        main: '#ff0000',
      },
      warning: {
        main: '#ff9528',
      },
    },
    typography: {
        fontFamily: ['margin-mvb', 'sans-serif'].join(','),
    }
});

let darkTheme = createTheme({
    palette: {
        primary: {
          main: '#d6e8e7',
        },
        secondary: {
          main: '#ff9528',
          contrastText: '#d6e8e7'
        },
        text: {
            primary: '#d6e8e7',
            secondary: '#d6e8e7',
        },
    },
    typography: {
      fontFamily: ['margin-mvb', 'sans-serif'].join(','),
  }
});

mainTheme = responsiveFontSizes(mainTheme);
darkTheme = responsiveFontSizes(darkTheme);

export { mainTheme, darkTheme };