import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import './ExpensePanel.css';
import React, { useState, useEffect } from 'react';
import { expenseEnabled } from '../../services/api-service/ApiService';
import { AddExpense } from './AddExpense';
import { RenderIfAuthorized } from '../RenderIfAuthorized';
import { useIsAuthenticated } from '@azure/msal-react';
import { ViewExpenses } from './ViewExpenses';

enum DisplayStatus {
  None,
  Valid,
  Error
}

let path = window.location.pathname;

export function ExpensePanel() {
  let handleAddClick = () => {
    window.location.href = `${window.location.href}/add`;
  }

  let handleViewClick = () => {
    window.location.href = `${window.location.href}/view`;
  }

  const [eventId] = useState(path.split('/')[2]);
  const [isAdd] = useState(path.split('/')[3] === 'add');
  const [isView] = useState(path.split('/')[3] === 'view');
  const [displayStatus, setDisplayStatus] = useState(DisplayStatus.None);

  useEffect(() => {
    expenseEnabled(eventId).then((result) => {
      if (result) {
        setDisplayStatus(DisplayStatus.Valid);
      } else {
        setDisplayStatus(DisplayStatus.Error);
      }
    }).catch((error) => {
      setDisplayStatus(DisplayStatus.Error);
      console.error(error);
    });
  }, [eventId]);

  const isAuthenticated = useIsAuthenticated();

  return (
    <div>
      <h2>Expense Panel</h2>
      {!isAdd && !isView ? (
        <>
          {displayStatus === DisplayStatus.Valid ? (
            <div id="expense-panel-flex">
              <Card className='flex-card'>
                    <CardContent>
                      <Typography component="h2">
                        Add Transaction
                      </Typography>
                      <Typography variant="body2" component="p">
                        Add a new transaction to this event.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button variant="contained" onClick={() => handleAddClick()}>Open <LaunchIcon/></Button>
                    </CardActions>
                  </Card>
        
                  <RenderIfAuthorized level='admin' isAuthorized={isAuthenticated}>
                    <Card className='flex-card'>
                      <CardContent>
                        <Typography component="h2">
                          View/Edit Transactions
                        </Typography>
                        <Typography variant="body2" component="p">
                          View, edit, and delete transactions for this event.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button variant="contained" onClick={() => handleViewClick()}>Open <LaunchIcon/></Button>
                      </CardActions>
                    </Card>
                  </RenderIfAuthorized>
              </div>
          ) : (<></>)}
        {displayStatus === DisplayStatus.Error ? (
          <Typography component="h3">
            Expenses are not enabled for this event.
          </Typography>
        ) : (<></>)}
        </>
    ) : (<></>)}

    <>
      {isAdd ? (<AddExpense/>) : (<></>)}
    </>

    <>
      {isView ? (<ViewExpenses/>) : (<></>)}
    </>

    </div>
  );
}