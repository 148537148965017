import { Button, InputAdornment, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import './AddExpense.css';
import React, { useState, useEffect } from 'react';
import { expenseEnabled, submitExpense, uploadTransactionDocument } from '../../../services/api-service/ApiService';
import { GlobalLoader } from '../../GlobalLoader';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';

import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs, { Dayjs } from 'dayjs';
import { TransactionModel } from '../../../services/types/TransactionModel';

enum DisplayStatus {
  None,
  Valid,
  Error
}

class FieldErrors {
  transType: string = "";
  category: string = "";
  vendor: string = "";
  description: string = "";
  amount: string = "";
  date: string = "";
  document: string = "Transaction Document is Required";
}

let path = window.location.pathname;

export function AddExpense() {
  const [eventId] = useState(path.split('/')[2]);
  const [displayStatus, setDisplayStatus] = useState(DisplayStatus.None);
  const [transaction, setTransaction] = useState(new TransactionModel());

  useEffect(() => {
    expenseEnabled(eventId).then((result) => {
      if (result) {
        setDisplayStatus(DisplayStatus.Valid);
      } else {
        setDisplayStatus(DisplayStatus.Error);
      }
    }).catch((error) => {
      setDisplayStatus(DisplayStatus.Error);
      console.error(error);
    });
  }, [eventId]);

  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);

  //Submit Expense
  const [displayLoader, setDisplayLoader] = useState(false);
  const [loaderView, setLoaderView] = useState<'loading' | 'success' | 'error'>('loading');

  async function handleTransactionSubmit() {
    setLoaderView('loading');
    setDisplayLoader(true);
    
    submitExpense(transaction, eventId).then((id: number) => {
      uploadTransactionDocument(id, (file as any)).then(() => {
        setLoaderView('success');
        setTimeout(() => {
          setDisplayLoader(false);
          window.location.href=`../${eventId}`;
        }, 3000);
      }).catch((e) => {
        console.error(e);
        setLoaderView('error');
        setTimeout(() => {
          setDisplayLoader(false);
        }, 3000);
      })

    }).catch((e) => {
      setLoaderView('error');

      setTimeout(() => {
        setDisplayLoader(false);
      }, 3000);

      return;
    });
  }

  //File Handling
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    checkFormState(transaction, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, transaction])

  function handleUploadClick(event: any) {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  }

  function handleFileClear() {
    setFile(undefined);
    setFileName('');

    if (document.getElementById('file-upload') !== null) {
      (document.getElementById('file-upload') as any).value = '';
    }

    checkFormState(transaction);
  }

  //Date Handling
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  //Form Handling
  const [fieldErrors, setFieldErrors] = useState(new FieldErrors());
  const [canSubmit, setCanSubmit] = useState(false);


  //TODO: Check errors and false canSubmit values in data update handlers.
  function checkFormState(trans: TransactionModel, checkDoc: boolean = true) {
    let errors = JSON.parse(JSON.stringify(fieldErrors));
    let canSubmit = true;

    if (trans.type?.length !== 0) {
      errors.transType = "";
    }
    else {
      errors.transType = "This field is required.";
      canSubmit = false;
    }

    if (trans.category?.length !== 0) {
      if (categoryOptions.includes(trans.category)) {
        errors.category = "";
      }
      else {
        errors.category = "Invalid category.";
        canSubmit = false;
      }
    }
    else {
      errors.category = "This field is required.";
      canSubmit = false;
    }

    if (trans.vendor?.length !== 0) {
      errors.vendor = "";
    }
    else {
      errors.vendor = "This field is required.";
      canSubmit = false;
    }

    if (trans.description?.length !== 0) {
      errors.description = "";
    }
    else {
      errors.description = "This field is required.";
      canSubmit = false;
    }

    if (!isNaN(trans.amount)) {
      if (trans.amount > 0) {
        errors.amount = "";
      }
      else {
        errors.amount = "Amount must be greater than 0.";
        canSubmit = false;
      }
    }
    else {
      errors.amount = "This field is required.";
      canSubmit = false;
    }

    if (selectedDate && selectedDate !== null) {
      if (selectedDate < dayjs(new Date())) {
        errors.date = "";
      }
      else {
        errors.date = "Date must be in the past.";
        canSubmit = false;
      }
    }
    else {
      errors.date = "This field is required.";
      canSubmit = false;
    }
    
    if (file !== undefined) {
      errors.document = "";
    }
    else {
      errors.document = "This field is required.";
      canSubmit = false;
    }

    if (file !== undefined) {
      errors.document = "";
    }
    else {
      errors.document = "This field is required.";
      canSubmit = false;
    }

    setCanSubmit(canSubmit);
    setFieldErrors(errors);
  }

  //Data Handling
  let handleTypeChange = (type: 'Income' | 'Expense') => {
    let trans = JSON.parse(JSON.stringify(transaction));
    trans.type = type;
    setTransaction(trans);

    if (type === 'Income') {
      setCategoryOptions(['Registration', 'Sponsorship', 'Ad Revenue', 'Contract Income', 'Reimbursement', 'Other']);
    } else {
      setCategoryOptions(['Transportation', 'Food', 'Equipment', 'Venue', 'Contractor Payment', 'Other']);
    }

    checkFormState(trans);
  }

  function handleDateChange(val: Dayjs | null | undefined) {
    let trans: TransactionModel = JSON.parse(JSON.stringify(transaction));

    if (val !== null) {
      trans.date = val?.toDate();
    }
    else {
      trans.date = dayjs('today').toDate();
    }

    setSelectedDate(val ?? dayjs('today'));
    setTransaction(trans);
    checkFormState(trans);
  }

  const [amountString, setAmountString] = useState('0');

  function handleAmountChange(val: string) {

    let trans: TransactionModel = JSON.parse(JSON.stringify(transaction));
    trans.amount = Math.floor(parseFloat(val) * 100)/100;

    if (isNaN(parseFloat(val))) {
      setAmountString('');
      trans.amount = 0;
    }
    //If user tries to enter more than 3 decimal places, trim the input.
    else if ((parseFloat(val) * 100) % 1 !== 0) {
      setAmountString((Math.floor(parseFloat(val) * 100)/100).toFixed(2));
    }
    //If use tries to enter excess zeros after the decimal, trim the input.
    else if (val.split('.').length > 1 && val.split('.')[1].length > 2) {
      setAmountString(val.slice(0, -1));
    }
    //If user tries to put a zero in front of a number that is not < 1, trim the input.
    else if (parseInt(val.split('.')[0]) < (Math.pow(10, val.split('.')[0].length - 1)) && val.split('.')[0].length > 1) {
      setAmountString(val.slice(1));
    }
    else {
      setAmountString(val);
    }

    setTransaction(trans);

    checkFormState(trans);
  }

  function editGenericField(prop: 'description' | 'vendor' | 'category', val: string) {
    let trans = JSON.parse(JSON.stringify(transaction));
    trans[prop] = val;
    setTransaction(trans);

    checkFormState(trans);
  }

  return (
    <div id="expense-add-center">
      <div id="expense-add-root">
        <GlobalLoader display={displayLoader} view={loaderView}></GlobalLoader>
        {displayStatus === DisplayStatus.Valid ? (<>
            <Stack spacing={4}>
              <Typography component="h2" variant='h6'>Add Transaction</Typography>
              <Grid container spacing={1}>
                <Grid xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Transaction Type</InputLabel>
                    <Select
                      autoWidth
                      label="Transaction Type"
                      labelId="type-label"
                      onChange={(event) => (handleTypeChange(event.target.value as any))}
                      error={fieldErrors.transType.length !== 0}
                      value={transaction.type ?? ''}
                    >
                      <MenuItem value="Income" key="type-income">Income</MenuItem>
                      <MenuItem value="Expense" key="type-expense">Expense</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={8}>
                  <FormControl
                    fullWidth
                    disabled={(categoryOptions.length === 0)}
                  >
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                      autoWidth={true}
                      labelId='category-label'
                      label="Category"
                      error={fieldErrors.category.length !== 0}
                      value={transaction.category ?? ''}
                      onChange={(event) => (editGenericField('category', event.target.value as any))}
                    >
                      {categoryOptions.map((option: string, i: number) => {
                        return (
                          <MenuItem value={option} key={`${transaction.type}-category-${i}`}>
                            {option}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <TextField
                variant='outlined'
                label='Vendor'
                fullWidth
                helperText="Where did the money go to or come from?"
                value={transaction.vendor ?? ''}
                error={fieldErrors.vendor.length !== 0}
                onChange={(event) => (editGenericField('vendor', event.target.value))}
              />

              <TextField
                variant='outlined'
                label='Description'
                fullWidth
                helperText="EX: Venue Payment"
                value={transaction.description ?? ''}
                error={fieldErrors.description.length !== 0}
                onChange={(event) => (editGenericField('description', event.target.value))}
              />

              <Grid container spacing={1}>
                <Grid xs={6}>
                  <TextField
                    variant='outlined'
                    label='Amount'
                    fullWidth
                    type={'number'}
                    value={amountString}
                    error={fieldErrors.amount.length !== 0}
                    onChange={(event) => (handleAmountChange(event.target.value))}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />
                </Grid>

                <Grid xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="Transaction Date"
                      value={selectedDate}
                      openTo="day"
                      views={['year', 'month', 'day']}
                      onChange={(newValue) => {
                        handleDateChange(newValue);
                      }}
                      renderInput={(params) => <TextField error={fieldErrors.date.length !== 0} {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Stack direction={'row'} alignItems='center'>
                <Button variant='outlined' component='label' style={{maxWidth: '50%'}}>
                  <UploadFileIcon/>
                  Upload Transaction Document
                  <input id='file-input' type={'file'} accept="image/png, image/jpeg, application/pdf" onChange={handleUploadClick} hidden/>
                </Button>
                <IconButton disabled={fileName.length === 0} onClick={() => handleFileClear()}>
                  <CloseIcon/>
                </IconButton>
                <Typography variant='body1'>{fileName}</Typography>
              </Stack>

              {(fieldErrors.document.length > 0) ? (<Typography textAlign='left' variant='body1'>Transaction Document is Required</Typography>) : (<></>)}

              <Button variant='contained' disabled={!canSubmit} onClick={() => handleTransactionSubmit()}>Add Transaction<AddCircleIcon/></Button>

            </Stack>
        </>) : (<></>)}
        {displayStatus === DisplayStatus.Error ? (
          <Typography component="h3">
            Expenses are not enabled for this event.
          </Typography>
        ) : (<></>)}
      </div>
    </div>
  );
}