import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CasterModel } from '../../../services/types/CasterModel';
import { adminDeleteCasterProfile, getAllAssignments, getAllCasters, getCurrentDashboards, updateCasterAssignments } from '../../../services/api-service/ApiService';
import { GlobalLoader } from '../../GlobalLoader';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { Route, Routes, useNavigate } from 'react-router-dom';
import { CasterForm } from '../CasterForm';
import { DashboardModel } from '../../../services/types/DashboardModel';

export function CasterAdmin() {
  return (
    <Routes>
      <Route path='/edit/:ID' element={<CasterForm type='edit' userType='admin'/>}/>
      <Route path='/' element={<CasterView/>}/>
    </Routes>
  )
}

export function CasterView() {

  const navigate = useNavigate();

  function handleEdit(val: any) {
    navigate(`/admin/casters/edit/${val}`)
  }

  const [toDelete, setToDelete] = useState<number>(-1);
  function handleDeleteClick(val: any) {
    setToDelete(val);
    setShowConf(true);
  }

  function handleDeleteConf() {
    setShowConf(false);
    setLoaderView('loading');
    setShowLoader(true);

    adminDeleteCasterProfile(toDelete).catch(() => {
      setLoaderView('error');
      setTimeout(() => {
        setShowLoader(false);
      }, 3000);
    }).then(() => {
      loadCasters();
    });
  }

  const [toAssign, setToAssign] = useState<number | undefined>(undefined);

  function handleAssignClick(id: number) {
    setToAssign(id);
    setShowAssign(true);
  }

  const [showAssign, setShowAssign] = useState(false);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'sponsor_prefix', headerName: 'Sponsor Prefix', width: 150 },
    { field: 'name', headerName: 'Display Name', width: 150 },
    { field: 'pronouns', headerName: 'Pronouns', width: 150 },
    { field: 'discord_tag', headerName: 'Discord', width: 150 },
    { field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => (
        <>
          <Tooltip title='Assign Caster To Event'>
            <IconButton onClick={() => { handleAssignClick(params.id as number) }}>
              <AssignmentIcon/>
            </IconButton>
          </Tooltip>

          <Tooltip title='Edit Caster'>
            <IconButton onClick={() => { handleEdit(params.id) }}>
              <EditIcon/>
            </IconButton>
          </Tooltip>
          
          <Tooltip title='Delete Caster'>
            <IconButton onClick={() => { handleDeleteClick(params.id) }}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        </>
      )
    },
  ];

  const [rows, setRows] = useState<CasterModel[]>([]);

  const [showLoader, setShowLoader] = useState(false);
  const [loaderView, setLoaderView] = useState<'success' | 'error' | 'loading'>('loading');

  const [dashboards, setDashbords] = useState<DashboardModel[]>([]);

  const [casterDashboards, setCasterDashboards] = useState<{[key: string]: DashboardModel}>({});

  const [assignments, setAssignments] = useState<{[key: number]: string[]}>({});

  async function loadCasters() {
    setShowLoader(true);
    setLoaderView('loading');

    let hasError = false;

    let casters: CasterModel[] = await getAllCasters().catch((e) => {
      hasError = true;
      return [];
    });

    let dashboards: DashboardModel[] = await getCurrentDashboards().catch((e) => {
      hasError = true;
      return [];
    });

    let assignments = await getAllAssignments().catch((e) => {
      hasError = true;
      return {};
    });

    if (assignments) setAssignments(assignments);

    if (hasError) {
      setLoaderView('error');
    }
    else {
      setRows(casters);
      
      let dash: {[key: string]: DashboardModel} = {};

      for (let i = 0; i < dashboards.length; i++) {
        dash[dashboards[i].id] = dashboards[i];
      }

      setDashbords(dashboards);
      setCasterDashboards(dash);
      

      setLoaderView('success');
      setTimeout(() => {
        setShowLoader(false);
      }, 3000);
    }
  }

  useEffect(() => {
    loadCasters();
  }, []);

  const [pageSize, setPageSize] = useState(10);

  const [showConf, setShowConf] = useState(false);

  function handleAssignClose() {
    setShowAssign(false);
    setToAssign(undefined);
    loadCasters();
  }

  function saveAssignments() {
    setLoaderView('loading');
    setShowLoader(true);

    updateCasterAssignments('caster', toAssign ?? -1, assignments[toAssign ?? -1] ?? []).catch(() => {
      setLoaderView('error');
      setTimeout(() => {
        setShowLoader(false);
      }, 3000);
    }).then(() => {
      handleAssignClose();
    });
  }

  function assignCaster(id: string) {
    let newAssign = {...assignments};
    if (toAssign) {
      if (newAssign[toAssign]) {
        newAssign[toAssign].push(id);
      }
      else {
        newAssign[toAssign] = [id];
      }
    }

    setAssignments(newAssign);
  }

  function unassignCaster(id: string) {
    let newAssign = {...assignments};
    if (toAssign) {
      if (newAssign[toAssign]) {
        newAssign[toAssign] = newAssign[toAssign].filter((val) => val !== id);
      }
    }

    setAssignments(newAssign);
  }

  

  return (
    <>
      <GlobalLoader display={showLoader} view={loaderView}/>

      <Dialog open={showConf} onClose={() => { setShowConf(false) }}>
        <DialogTitle>
          Are you sure you want to delete this caster profile?
        </DialogTitle>

        <DialogContent>
          <Typography>This action cannot be undone.</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => { setShowConf(false) }}>Cancel</Button>
          <Button variant='contained' onClick={() => handleDeleteConf()}><DeleteIcon className='icon-spacing'/> Delete Profile</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAssign} onClose={() => { handleAssignClose() }}>
        <DialogTitle>
          Assign Caster To Event
        </DialogTitle>

        <DialogContent>
          <Typography>Events Assigned To</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event ID</TableCell>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Event Game</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(assignments[toAssign ?? -1] ?? []).map((id, i) => (
                  <TableRow style={ i % 2? { background : "#e1edec" }:{ background : "white" }} key={`assigned-row-${i}`}>
                    <TableCell>{casterDashboards[id].id}</TableCell>
                    <TableCell>{casterDashboards[id].title}</TableCell>
                    <TableCell>{casterDashboards[id].game}</TableCell>
                    <TableCell>
                      <Tooltip title='Unassign From Event'>
                        <IconButton onClick={() => unassignCaster(casterDashboards[id].id)}>
                          <RemoveIcon/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography sx={{marginTop: '50px'}}>Events Available To Assign</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event ID</TableCell>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Event Game</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dashboards.filter((d) => !(assignments[toAssign ?? -1] ?? []).includes(d.id)).map((dash, i) => (
                  <TableRow style={ i % 2? { background : "#e1edec" }:{ background : "white" }} key={`assigned-row-${i}`}>
                    <TableCell>{dash.id}</TableCell>
                    <TableCell>{dash.title}</TableCell>
                    <TableCell>{dash.game}</TableCell>
                    <TableCell>
                      <Tooltip title='Assign To Event'>
                        <IconButton onClick={() => assignCaster(dash.id)}>
                          <AddIcon/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => { handleAssignClose() }}>Close Without Saving</Button>
          <Button variant='contained' onClick={() => { saveAssignments() }}>Save <SaveIcon/></Button>
        </DialogActions>
      </Dialog>

      <Typography style={{marginBottom: '25px'}} component="h2" variant='h6'>Caster Management</Typography>

      <div style={{height: 111+(52*pageSize), width: 900, margin: '0 auto', marginBottom: 78}}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(val) => {setPageSize(val)}}
          disableSelectionOnClick
        />
      </div>
    </>
  )
}