import React, { useRef, useState } from 'react';

import { Checkbox, Button, Card, CardContent, CardActions, Dialog, DialogTitle, Stack, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, DialogContent, DialogContentText } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { Delete } from '@mui/icons-material';

import { GlobalLoader } from '../../GlobalLoader';

import { DashboardModel } from '../../../services/types/DashboardModel';
import { deleteDashboard, editDashboards, getCurrentDashboards } from '../../../services/api-service/ApiService';

import './EditDashboard.css';

export interface EditDashboardProps {
  onClose: () => void;
  open: boolean;
}

export function EditDashboard(props: EditDashboardProps) {
  const { onClose, open } = props;

  const [canSubmit, setCanSubmit] = useState(false);

  const setOgDashboards = useState([])[1];
  const setDashboards = useState([])[1];

  const ogDashboards = useRef<DashboardModel[]>([]);
  const dashboards = useRef<DashboardModel[]>(JSON.parse(JSON.stringify(ogDashboards.current)));

  if (open && ogDashboards.current.length === 0) {
    refreshOgDashboards();
  }

  function refreshOgDashboards(): Promise<void> {
    return new Promise((resolve, reject) => {
      getCurrentDashboards().then((res) => {
        setOgDashboards(JSON.parse(JSON.stringify(res)));
        setDashboards(JSON.parse(JSON.stringify(res)));
        ogDashboards.current = res;
        dashboards.current = res;
        resolve();
        checkCanSubmit();
      }).catch((e) => {
        console.error(e);
        reject();
      });
    });
  }

  //Function on cancel
  function closeEditDashboard() {
    refreshOgDashboards();
    closeConfirmation();
    onClose();
  }

  //Function on save
  function handleClose() {
    //Refresh Panel
    refreshOgDashboards();
    onClose();
  }

  const [loader, setLoader] = useState(false);

  function handleDashEdit() {
    setLoader(true);

    let body: DashboardModel[] = [];
    for (let i = 0; i < dashboards.current.length; i++) {
      if (!deepAreEquals(dashboards.current[i], ogDashboards.current[i])) {
        body.push(dashboards.current[i]);
      }
    }
    if (body.length > 0) {
      editDashboards(body).catch((e) => {
        console.error(e);
      }).finally(() => {
        setLoader(false);
        handleClose();
      })
    }
    else {
      setLoader(false);
      handleClose();
    }
  }

  const [confirmDialog, setConfirmDialog] = useState(false);

  function openConfirmation() {
    setConfirmDialog(true);
  }

  function closeConfirmation() {
    setConfirmDialog(false);
  }

  const [deleteDialog, setDeleteDialog] = useState(false);

  function closeDelete() {
    setDeleteDialog(false);
  }

  function ConfirmationDialog() {
    return (
      <Dialog open={confirmDialog} onClose={closeConfirmation}>
        <DialogTitle>Are you sure you want to leave without saving changes?</DialogTitle>
        <Stack direction='row' margin={2} spacing={2}>
          <Button variant='contained' onClick={closeConfirmation}>Go Back</Button>
          <Button onClick={closeEditDashboard}>I'm Sure</Button>
        </Stack>
      </Dialog>
    )
  }

  function DeleteConfirmationDialog() {
    return (
      <Dialog open={deleteDialog} onClose={closeDelete}>
        <DialogTitle>Are you sure you'd like to delete this dashboard?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This change will take place immediately.
          </DialogContentText>
        </DialogContent>
        <Stack direction='row' margin={2} spacing={2}>
          <Button variant='contained' onClick={closeDelete}>No, Keep It</Button>
          <Button onClick={handleDelete}>I'm Sure</Button>
        </Stack>
      </Dialog>
    )
  }

  function handleDashboardEdit(index: number, field: 'title' | 'game' | 'url' | 'adminonly' | 'expenseenabled', newValue: string | boolean) {
    let newDash = JSON.parse(JSON.stringify(dashboards.current));

    newDash[index][field] = newValue;
    
    dashboards.current = newDash;
    setDashboards(newDash);

    checkCanSubmit();
  }

  function checkCanSubmit() {
    let hasError = false;

    let s = false;
    for (let i = 0; i < dashboards.current.length; i++) {
      if (deepAreEquals(dashboards.current[i], ogDashboards.current[i]) === false) {
        s = true;
        if (urlIsValid(dashboards.current[i].url) === false) {
          hasError = true;
        }
      }
    }

    if (s === true && hasError === false) {
      setCanSubmit(true);
    }
    else {
      setCanSubmit(false);
    }
  }

  const [indexToDelete, setIndexToDelete] = useState(-1);

  function confirmDelete(index: number) {
    setDeleteDialog(true);
    setIndexToDelete(index);
  }

  function handleDelete() {
    setDeleteDialog(false);
    if (indexToDelete !== -1 && indexToDelete < dashboards.current.length) {
      setLoader(true);
      deleteDashboard(dashboards.current[indexToDelete].id).then(() => {
        let newDash = JSON.parse(JSON.stringify(dashboards.current));
        newDash.splice(indexToDelete, 1);
        dashboards.current = newDash;
        setDashboards(newDash);
        refreshOgDashboards();
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        setLoader(false);
        setIndexToDelete(-1);
        checkCanSubmit();
      });
    }
  }

  function deepAreEquals(a: DashboardModel, b: DashboardModel): boolean {
    try {
      if (a.id === b.id && a.title === b.title && a.game === b.game && a.url === b.url && a.iconurl === b.iconurl && a.adminonly === b.adminonly && a.expenseenabled === b.expenseenabled) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return false;
    }
  }

  function urlIsValid(url: string): boolean {
    const URL_REGEX: RegExp = /^https?:\/\/[A-Za-z0-9]+\.level1\.tv:?[0-9]*$/;
    
    if (!url || url.length === 0) {
      return true;
    }

    else if (!url.match(URL_REGEX)) {
      return false;
    }

    else {
      return true;
    }

  }

  return(
    <Dialog open={open} onClose={openConfirmation} fullWidth maxWidth='md'>
      <GlobalLoader display={loader} view={'loading'}/>
      <ConfirmationDialog/>
      <DeleteConfirmationDialog/>
      <DialogTitle>Edit Dashboards</DialogTitle>
      <Card>
        <CardContent>
        <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event ID</TableCell>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Event Game</TableCell>
                  <TableCell>Dashboard Link</TableCell>
                  <TableCell>Admin Only</TableCell>
                  <TableCell>Expenses</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboards.current.map((db: DashboardModel, i: number) => (
                  <TableRow style={ i % 2? { background : "#e1edec" }:{ background : "white" }} key={db.id} >
                    <TableCell><TextField disabled defaultValue={db.id} fullWidth size='small'></TextField></TableCell>
                    <TableCell><TextField fullWidth onChange={(_e) => handleDashboardEdit(i, 'title', _e.target.value)} defaultValue={db.title} size='small' ></TextField></TableCell>
                    <TableCell><TextField fullWidth  onChange={(_e) => handleDashboardEdit(i, 'game', _e.target.value)} defaultValue={db.game} size='small'></TextField></TableCell>
                    <TableCell><TextField error={!urlIsValid(db.url)} fullWidth size='small' onChange={(_e) => handleDashboardEdit(i, 'url', _e.target.value)} defaultValue={db.url}></TextField></TableCell>
                    <TableCell><Checkbox checked={db.adminonly} onChange={(_e) => {handleDashboardEdit(i, 'adminonly', _e.target.checked)}}></Checkbox></TableCell>
                    <TableCell><Checkbox checked={db.expenseenabled} onChange={(_e) => {handleDashboardEdit(i, 'expenseenabled', _e.target.checked)}}></Checkbox></TableCell>
                    <TableCell><IconButton onClick={() => confirmDelete(i)}><Delete/></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Button onClick={openConfirmation}>Close Without Saving</Button>
          <Button variant='contained' disabled={!canSubmit} onClick={() => handleDashEdit()}>Save<SaveIcon/></Button>
        </CardActions>
      </Card>
    </Dialog>
  );
}