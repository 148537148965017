import { Alert, Card, CardContent, IconButton, Snackbar, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Graphic {
  url: string;
  width: number;
  height: number;
}

interface GraphicProps {
  graphic: Graphic;
}
export function GraphicItem(props: GraphicProps) {

  const [showCopy, setShowCopy] = useState(false);

  function handleCopy() {
      navigator.clipboard.writeText(props.graphic.url);
      setShowCopy(true);
  }

  function handleOpenGraphic() {
    window.open(props.graphic.url, '_blank');
  }

  return (
    <>
    <Snackbar open={showCopy} autoHideDuration={6000} onClose={() => setShowCopy(false)}>
        <Alert severity='success' sx={{width: '100%'}} onClose={() => setShowCopy(false)}>
          Graphic link copied to clipboard!
        </Alert>
      </Snackbar>

      <Card>
        <CardContent>
          <Grid alignItems={'center'} container spacing={2}>
            <Grid xs={9}>
              <Stack>
                <Tooltip title="Click to open in new tab.">
                  <Typography
                    onClick={handleOpenGraphic}
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                  >{props.graphic.url.split('/').at(-1)?.split('?')[0]}</Typography>
                </Tooltip>
                <Typography variant='body2' color={'text.secondary'}>
                  {props.graphic.width} x {props.graphic.height}
                </Typography>
              </Stack>
            </Grid>

            <Grid xs={1}>
              <Tooltip title="Copy Graphic Link to Clipboard">
                <IconButton onClick={() => {handleCopy()}}><ContentCopyIcon/></IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
    </>
  )
}