import { Button, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { GlobalLoader } from '../GlobalLoader';

import ReplayIcon from '@mui/icons-material/Replay';
import LogoutIcon from '@mui/icons-material/Logout';

import { CLIENT_ID } from '../../services/configs/DiscordConfig';

import './DiscordLogin.css'
import { checkDiscordToken, getCasterInfoFromToken, getDiscordToken } from '../../services/api-service/ApiService';
import { CasterModel } from '../../services/types/CasterModel';
import { CasterForm } from './CasterForm';

import './CasterPanel.css';
import { CasterOverview } from './CasterOverview';

export function DiscordLogin() {

  function handleDiscordRedirect() {
    window.location.href = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&scope=identify&redirect_uri=${encodeURIComponent(`${window.location.origin}/caster`)}`;
  }

  return(
    <Button variant='contained' sx={{backgroundColor: "#5865F2"}} size="large" onClick={() => handleDiscordRedirect()}>
      <img style={{height: "1em", paddingRight: "1em"}} alt="Discord Logo" src="/logos/discord-white.png"/>
      Login With Discord
    </Button>
  )
}

export function CasterPanel() {
  const [isAuthed, setIsAuthed] = useState<any>(undefined);
  const [isAuthing, setIsAuthing] = useState<boolean>(false);
  const [hasProfile, setHasProfile] = useState<boolean | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);

  //eslint-disable-next-line
  const [profile, setProfile] = useState<CasterModel | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());

  async function getProfile() {
    let fetchedProfile = await getCasterInfoFromToken().catch(() => { return undefined; });

    if (fetchedProfile) {
      if (fetchedProfile.has_profile) {
        setHasProfile(true);
        if (location.pathname === '/caster') {
          navigate('/caster/view');
        }
      }
      else {
        navigate('/caster/new')
        setHasProfile(false);
      }

      setProfile(fetchedProfile);
    }
    else {
      setHasProfile(false);
      setIsError(true);
    }
  }

  function handleInvalidToken() {
    setIsAuthed(false);
    setHasProfile(undefined);

    navigate('/caster');

    window.localStorage.removeItem('discordAuth');
  }

  async function handleHasToken() {
    let validToken = await checkDiscordToken().catch((e) => console.error(e));

    if (!validToken) {
      handleInvalidToken();
      return;
    }
    
    else {
      setIsAuthed(true);
      getProfile();
    }

    setIsAuthing(false);  
  }

  const sentReq = useRef(false);
  let token = window.localStorage.getItem('discordAuth');
  useEffect(() => {
    if (token && token.length > 0) {
      handleHasToken();
    }

    else if (!sentReq.current) {
      if (params?.code) {
        sentReq.current = true;
        setIsAuthing(true);
        const code = params.code;
  
        getDiscordToken(code).catch(e => {
          console.error(e);
          setIsError(true);
          setHasProfile(undefined);
        }).then(() => {
          setIsAuthed(true);
          getProfile();
        }).finally(() => {
          setIsAuthing(false);
          navigate('/caster');
        });

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function DiscordLogout() {

    const navigate = useNavigate();
  
    function handleLogout() {
      window.localStorage.removeItem('discordAuth');
      setIsAuthed(undefined);
      setIsAuthing(false);
      setHasProfile(undefined);
      setIsError(false);
      setProfile(undefined);
      navigate('/caster');
    }
    
    return (
      <Button className='logout-button' variant='contained' onClick={() => handleLogout()}>
        <LogoutIcon/> Logout Of Caster Panel
      </Button>
    )
  }

  return (
    <div className='root-panel-container'>
      <h2>Caster Panel</h2>

      {!isAuthed && !isAuthing ? <DiscordLogin/> : <></>}
      {isAuthed ? <DiscordLogout/> : <></>}

      {isAuthed && !isError ?
      <>
        <Routes>
          <Route path='/new' element={<CasterForm type='new'/>}/>
          <Route path='/edit' element={<CasterForm type='edit'/>}/>
          <Route path='/view' element={<CasterOverview/>}/>
        </Routes>
      </>
      :
      <></>}

      {((hasProfile === undefined && isAuthed) || isAuthing) && !isError ? <GlobalLoader display={true} view="loading" /> : <></>}

      {isError ? <>
        <Typography>An error occurred processing your request. Please try again.</Typography>
        <br/>
        <Button variant="contained" onClick={() => navigate('/caster')}><ReplayIcon/>Try Again</Button>
      </> : <></>}
    </div>
  );
}