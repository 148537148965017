export class ICasterSocialInfo {
  service_name: string = '';
  service_identifier: string = '';
}

export class CasterModel {
  id: number = -1;
  discord_id: string = '';
  discord_username: string = '';
  discord_discriminator: string = '';
  discord_tag: string = '';
  discord_avatar: string = '';

  has_profile: boolean = false;
  sponsor_prefix: string = '';
  name: string = '';
  socials: {[key: string]: ICasterSocialInfo} = {};
  pronouns: string = '';
  share_key: string = '';
  sharing_enabled: boolean = false;
}
