import { IPublicClientApplication } from "@azure/msal-browser";
import { allowedGroups, loginRequest } from "../configs/MSALConfig";

export const hasAuthLevel = (instance: IPublicClientApplication, isAuthorized: boolean, neededLevel: 'unauthorized' | 'admin' | 'user'): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    instance.setActiveAccount(instance.getAllAccounts()[0]);

    let level = await getAuthLevel(instance, isAuthorized);

    if (neededLevel === "admin") {
      if (level === "admin") resolve(true);
    } 
    else if (neededLevel === "user") {
      if (level === "admin" || level === "user") resolve(true);
    }
    else {
      resolve(false);
    }
  });
};

export const getAuthLevel = (instance: IPublicClientApplication, isAuthorized: boolean): Promise<'unauthorized' | 'admin' | 'user'> => {
  return new Promise(async (resolve, reject) => {
    let level: 'unauthorized' | 'admin' | 'user' = 'unauthorized';

    if (instance.getActiveAccount()) {
      let res = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount() ?? instance.getAllAccounts()[0],
      }).catch((e) => {
        console.error(e);
        resolve('unauthorized');
      });

      if (!res?.idTokenClaims || !(res.idTokenClaims as any).groups) {
        if (isAuthorized) {
          level = 'user';
        }
      } else {
        let groups = (res.idTokenClaims as any).groups;

        if (groups.includes(allowedGroups.adminGroup)) {
          level = "admin";
        }
        else if (isAuthorized) {
          level = 'user';
        }
      }
    }
    else {
      resolve('unauthorized');
    }

    resolve(level);
  });
}