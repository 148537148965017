import React, { useEffect, useState } from 'react';

import { DashboardModel } from '../../services/types/DashboardModel';

import { Card, CardActions, CardContent, CardMedia, Typography, Button, Chip, Stack, IconButton, Dialog, Tooltip, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, Snackbar, Alert, Skeleton, Autocomplete } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';

import './DashboardItem.css';
import { Link, useNavigate } from 'react-router-dom';

import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { RenderIfAuthorized } from '../RenderIfAuthorized';
import { useIsAuthenticated } from '@azure/msal-react';
import { Refresh, Save, Stream } from '@mui/icons-material';
import { GlobalLoader } from '../GlobalLoader';
import { getNodeCGKey, refreshNodeCGKey } from '../../services/api-service/ApiService';

export function DashboardItem(props: { dashboard: DashboardModel, twitchOptions: {channel_name: string, id: number}[], refresh: () => Promise<void> }) {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    let handleClick = () => {
        navigate(`/dashboard/${props.dashboard.id}/view`);
        //window.location.href = props.dashboard.url;
    }

    let handleExpenseClick = () => {
        window.location.href = `/expenses/${props.dashboard.id}`;
    }

    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

    const [graphicsKey, setGraphicsKey] = useState<string | undefined>(undefined);
    const [apiKey, setApiKey] = useState<string | undefined>(undefined);

    async function getKeys() {
        let graphics = await getNodeCGKey(props.dashboard.id, 'graphicsKey').catch(() => { return undefined });
        let api = await getNodeCGKey(props.dashboard.id, 'apiKey').catch(() => { return undefined });

        setGraphicsKey(graphics);
        setApiKey(api);
    }

    function handleSettingsOpen() {
        setSettingsOpen(true);
        getKeys();
    }

    function handleSettingsClose() {
        setSettingsOpen(false);

        //Allow animation to finish before state change.
        setTimeout(() => {
            setGraphicsKey(undefined);
            setApiKey(undefined);
        }, 200)
    }

    const [showTwitchDialog, setShowTwitchDialog] = useState(false);
    const [showCopy, setShowCopy] = useState(false);

    function handleCopyClick(type: 'graphicsKey' | 'apiKey') {
        let val = type === 'graphicsKey' ? graphicsKey : apiKey;
        navigator.clipboard.writeText(val ?? '');
        setShowCopy(true);
    }

    const [showRefresh, setShowRefresh] = useState(false);
    const [willRefresh, setWillRefresh] = useState<'graphicsKey' | 'apiKey' | undefined>(undefined);

    function handleRefreshClick(type: 'graphicsKey' | 'apiKey') {
        setWillRefresh(type);
        setShowRefresh(true);
    }

    const [loaderView, setLoaderView] = useState<'loading' | 'success' | 'error'>('loading');
    const [showLoader, setShowLoader] = useState(false);

    function handleRefreshConfirm() {
        setShowRefresh(false);
        setLoaderView('loading');
        setShowLoader(true);

        if (willRefresh) {
            refreshNodeCGKey(props.dashboard.id, willRefresh).then((key) => {
                if (willRefresh === 'graphicsKey') {
                    setGraphicsKey(key);
                } else {
                    setApiKey(key);
                }

                setShowLoader(false);
            }).catch(() => {
                setLoaderView('error');
                setTimeout(() => {
                    setShowLoader(false);
                }, 3000);
            });
        }
        
    }

    useEffect(() => {
        if (props.twitchOptions.length > 0) {
            let channel = props.twitchOptions.find((channel) => channel.id === props.dashboard.twitch_channel_id);
            setTwitchChannel(channel ?? null);
        }
    }, [props.twitchOptions, props.dashboard.twitch_channel_id]);

    const [twitchChannel, setTwitchChannel] = useState<{channel_name: string, id: number} | null>(null);

    function saveTwitchChannel() {
        let body = {
            twitch_channel_id: twitchChannel?.id ?? null
        };

        let token = localStorage.getItem('auth');
        fetch(`/api/dashboards/${props.dashboard.id}/update-twitch`, {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {Authorization: token ?? '', 'Content-Type': 'application/json'}
        }).then(() => {
            setShowTwitchDialog(false);
            setTimeout(() => {
                props.refresh();
            }, 250);
            
        }).catch(() => null);
    }

    function handleRefreshCancel() {
        setShowRefresh(false);

        //Allow animation to finish before state change.
        setTimeout(() => {
            setWillRefresh(undefined);
        }, 200);
    }

    return (
        <>
            <GlobalLoader view={loaderView} display={showLoader}></GlobalLoader>

            <Snackbar open={showCopy} autoHideDuration={2000} onClose={() => setShowCopy(false)}>
                <Alert severity='success' sx={{width: '100%'}} onClose={() => setShowCopy(false)}>
                    Copied to clipboard!
                </Alert>
            </Snackbar>

            <Dialog open={showRefresh} onClose={() => { handleRefreshCancel(); }}>
                <DialogTitle>
                    Are you sure you want to reset this {willRefresh === 'graphicsKey' ? 'graphics key' : 'API key'}?
                </DialogTitle>
                <DialogContent>
                    <Typography>This will immediately invalidate the current {willRefresh === 'graphicsKey' ? 'graphics key' : 'API key'}.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleRefreshCancel(); }}>Cancel</Button>
                    <Button variant='contained' onClick={() => { handleRefreshConfirm(); }}>Refresh {willRefresh === 'graphicsKey' ? 'Graphics Key' : 'API Key'}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showTwitchDialog} onClose={() => { setShowTwitchDialog(false); }}>
                <DialogTitle>
                    Assign Twitch Channel
                </DialogTitle>

                <DialogContent>
                    <Stack spacing={2} sx={{marginTop: '1rem', width: 300}}>
                        <Autocomplete
                            disablePortal
                            id="twitch-channel-select"
                            options={props.twitchOptions}
                            sx={{width: 300}}
                            renderInput={(params) => <TextField {...params} label="Twitch Channel"/>}
                            value={twitchChannel ?? null}
                            onChange={(event, value) => { setTwitchChannel(value); }}
                            getOptionLabel={(option) => option.channel_name}
                        />

                        <Typography variant={'caption'} textAlign='left'>
                            To add a new channel, send the account owner to <Link to={'/twitch-connect'}>https://hub.level1.tv/twitch-connect</Link> and ask them to sign in.
                        </Typography>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button variant={'contained'} onClick={() => { saveTwitchChannel(); }}>
                        <Save className='icon-spacing'/> Save
                    </Button>
                </DialogActions>

            </Dialog>

            <Dialog open={settingsOpen} onClose={() => { handleSettingsClose(); }}>
                <DialogTitle>
                    {props.dashboard.title} Graphics Settings
                </DialogTitle>

                <DialogContent>
                    {(graphicsKey && apiKey) ? (
                        <>
                        <Stack spacing={4} direction={'column'} alignItems={'flex-start'}>
                            <Stack spacing={0} direction={'column'}>
                                <Typography textAlign={'left'}>Graphics Key</Typography>
                                <Grid container spacing={2} alignItems={'center'}>
                                    <Grid xs={10}>
                                        <Tooltip title={'Click To Copy'}>
                                            <TextField
                                                variant='filled'
                                                value={graphicsKey}
                                                fullWidth
                                                multiline
                                                disabled
                                                onClick={() => { handleCopyClick('graphicsKey'); }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><ContentCopyIcon/></InputAdornment>
                                                }}
                                            />
                                        </Tooltip> 
                                    </Grid>
                                    <Grid xs={1}>
                                        <Tooltip title={'Reset Graphics Key'}>
                                            <IconButton onClick={() => { handleRefreshClick('graphicsKey'); }}>
                                                <Refresh/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Stack>

                            <Stack spacing={0} direction={'column'}>
                                <Typography textAlign={'left'}>API Key</Typography>
                                <Grid container spacing={2} alignItems={'center'}>
                                    <Grid xs={10}>
                                        <Tooltip title={'Click To Copy'}>
                                            <TextField
                                                variant='filled'
                                                value={apiKey}
                                                fullWidth
                                                multiline
                                                disabled
                                                onClick={() => { handleCopyClick('apiKey'); }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><ContentCopyIcon/></InputAdornment>
                                                }}
                                            />
                                        </Tooltip> 
                                    </Grid>
                                    <Grid xs={1}>
                                        <Tooltip title={'Reset API Key'}>
                                            <IconButton onClick={() => { handleRefreshClick('apiKey'); }}>
                                                <Refresh/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Stack>

                        </Stack>
                        </>
                    )
                    : (
                        <Stack spacing={4} direction={'column'} alignItems={'center'}>
                            <Skeleton variant={'rectangular'} width={302} height={149}/>
                            <Skeleton variant={'rectangular'} width={302} height={149}/>
                        </Stack>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button variant={'contained'} onClick={() => { handleSettingsClose() }}>
                        <CloseIcon className='icon-spacing'/> Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Card style={{backgroundColor: "#FAFAFA", width: 500, marginBottom: 50}}>
                <CardMedia
                    component={'img'}
                    image={`/api/event-covers/${props.dashboard.iconurl}`}
                    height={200}
                />
                <CardContent>
                    <Typography>{props.dashboard.title}</Typography>
                    <Typography variant='body2' color="text.secondary">{props.dashboard.game}</Typography>
                </CardContent>
                <CardActions>
                    <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                            {(props.dashboard.url && props.dashboard.url.length > 0) ? (<Button variant="contained" onClick={() => handleClick()}>Open Dashboard</Button>) : <></>}
                            {props.dashboard.expenseenabled ? (<Button variant="contained" onClick={() => handleExpenseClick()}>Open Expenses</Button>) : (<></>)}
                            {props.dashboard.adminonly ? (<Chip style={{marginLeft: 15}} label="Admin Only"/>) : (<></>)}
                        </Stack>

                        <div>
                            <RenderIfAuthorized isAuthorized={isAuthenticated} level={'admin'}>
                                <Tooltip title={'Assign Twitch Channel'}>
                                    <IconButton onClick={() => { setShowTwitchDialog(true); }}>
                                        <Stream/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={'Graphics Settings'}>
                                    <IconButton onClick={() => { handleSettingsOpen(); }}>
                                        <SettingsIcon/>
                                    </IconButton>
                                </Tooltip>
                            </RenderIfAuthorized>
                        </div>
                        
                    </Stack>
                    
                </CardActions>
            </Card>
        </>
    );
}