import React from "react";

import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface GlobalLoaderProps {
    display: boolean;
    view: 'loading' | 'error' | 'success';
    message?: string;
}

export function GlobalLoader(props: GlobalLoaderProps) {
    return (
        <Backdrop open={props.display} style={{zIndex: 1000}}>
            {props.view === 'loading' ? (
                <CircularProgress sx={{color: '#d6e8e7'}}/>
            ) : (
                <></>
            )}
            
            {props.view === 'success' ? (
                <DoneOutlineIcon sx={{color: '#4BB543'}}/>
            ) : (
                <></>
            )}

            {props.view === 'error' ? (
                <ErrorOutlineIcon sx={{color: '#F00'}}/>
            ) : (
                <></>
            )}
        </Backdrop>
    )
}