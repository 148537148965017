import React from 'react';
import { getCurrentDashboards, getTwitchOptions } from '../../services/api-service/ApiService';

import { DashboardModel } from '../../services/types/DashboardModel';

import { DashboardItem } from './DashboardItem';

import './Dashboard.css';
import { Skeleton } from '@mui/material';

export function Dashboard() {
  const [dashboards, setDashboards] = React.useState<DashboardModel[]>([]);
  const [twitchOptions, setTwitchOptions] = React.useState<{channel_name: string, id: number}[]>([]);

  async function getDashboards() {
    setDashboards([]);
    let fetchedDashboards = await getCurrentDashboards().catch(() => { return []; });
    setDashboards(fetchedDashboards);
  }

  React.useEffect(() => {
    async function getOptions() {
      let options = await getTwitchOptions().catch(() => { return []; });
      setTwitchOptions(options);
    }
    
    getDashboards();
    getOptions();
  }, []);

  return (
    <div id="dashboard-component-container">
      <div>
        <h2>Events</h2>
      </div>

      {dashboards.length === 0 ? <>
        <div id="dashboard-container">        
          {Array.from(new Array(5)).map((_item, index) => (
            <Skeleton key={`skeletion-${index}`} variant='rectangular' width={500} height={329} style={{marginBottom: 50, borderRadius: 3}}></Skeleton>
          ))}
        </div>
      </> : null}

      {(dashboards.length > 0)
      ?
      <>
        <div id="dashboard-container">
          {dashboards.map((dashboard: DashboardModel) => {
            return (
              <DashboardItem key={dashboard.id} dashboard={dashboard} twitchOptions={twitchOptions} refresh={getDashboards}/>
            )
          })}
        </div>
      </>
      :
      <></>}
    </div>
  );
}
