import './CasterForm.css';

import { Button, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Tooltip, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit';

import React, { useState, useEffect } from 'react';
import { CasterModel, ICasterSocialInfo } from '../../../services/types/CasterModel';

import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { GlobalLoader } from '../../GlobalLoader';
import { adminEditCaster, editCaster, getCasterById, getCasterInfoFromToken, submitNewCaster } from '../../../services/api-service/ApiService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export interface CasterFormProps {
  type: 'new' | 'edit';
  userType?: 'caster' | 'admin';
}

export function CasterForm(props: CasterFormProps) {
  const [title, setTitle] = useState('');

  const [profile, setProfile] = useState(new CasterModel());

  const navigate = useNavigate();
  const location = useLocation();

  const { ID } = useParams();

  useEffect(() => {
    if (!props.userType || props.userType === 'caster') {
      getCasterInfoFromToken().then((res) => {
        if (res) {
          if (location.pathname === '/caster/new' && res.has_profile) {
            navigate('/caster/view');
          }

          setProfile(res);

          if (res.socials && Object.keys(res.socials).length > 0) {

            Object.keys(res.socials).forEach((key) => {
              handleSocialSelect(key);
            });

            let newSocials = {...socials};

            newSocials = Object.values(res.socials);

            setSocials(newSocials);
          }
          else {
            setSocials([new ICasterSocialInfo()]);
          }
          setShowLoader(false);
        }
      }).catch(() => {
        return undefined;
      });
    }

    else {
      if (!ID) {
        navigate('/admin/casters');
      }
      else {
        getCasterById(ID).then((res) => {
          if (res) {
            if (location.pathname === '/caster/new' && res.has_profile) {
              navigate('/caster/view');
            }
  
            setProfile(res);
  
            if (res.socials && Object.keys(res.socials).length > 0) {
  
              Object.keys(res.socials).forEach((key) => {
                handleSocialSelect(key);
              });
  
              let newSocials = {...socials};
  
              newSocials = Object.values(res.socials);
  
              setSocials(newSocials);
              setShowLoader(false);
            }
            else {
              setLoaderView('error');
              setTimeout(() => {
                navigate('/admin/casters');
              }, 3000);
            }
            
          }
        }).catch(() => {
          setLoaderView('error');
              setTimeout(() => {
                navigate('/admin/casters');
              }, 3000);
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.type === 'new') {
      setTitle('Create Caster Profile');
    }
    else {
      setTitle('Edit Caster Profile');
    }
  }, [props.type]);

  class SocialField {
    serviceName: string;
    serviceValueName: string;
    adornment: string;
    selected: boolean = false;
    serviceValue: string = '';

    constructor(name: string, handleName: string, adornment: string) {
      this.serviceName = name;
      this.serviceValueName = handleName;
      this.adornment = adornment;
    }
  }
  const defaultSocialOptions = [
    new SocialField('Twitter', 'Handle', '@'),
    new SocialField('Twitch', 'Link', 'twitch.tv/'),
    new SocialField('YouTube', 'Handle', '@'),
    new SocialField('Metafy', 'Link', 'metafy.gg/@')
  ];

  let socialOpsObj: {[key: string]: SocialField} = {};

  defaultSocialOptions.forEach((op) => {
    socialOpsObj[op.serviceName] = op;
  })

  const [socialOptions, setSocialOptions] = useState(socialOpsObj);

  const [socials, setSocials] = useState<ICasterSocialInfo[]>(Object.values(profile.socials) ?? (props.type === 'new') ? [new ICasterSocialInfo()] : []);

  function handleSocialSelect(service: string) {
    let newOptions = {...socialOptions};
    newOptions[service].selected = true;
    setSocialOptions(newOptions);
  }

  function handleSocialDelete(service: string) {
    let newOptions = {...socialOptions};
    newOptions[service].selected = false;
    setSocialOptions(newOptions);
  }

  function handleSocialSelectChange(index: number, newVal: string) {
    let newSocials = [...socials];
    if (newSocials[index] && newSocials[index].service_name) {
      handleSocialDelete(newSocials[index].service_name);
    }

    newSocials[index] = new ICasterSocialInfo();
    newSocials[index].service_name = newVal;

    handleSocialSelect(newVal);

    setSocials(newSocials);
  }

  function getSocialLabel(service: string): string | undefined {
    if (service.length === 0) return undefined;

    let obj = socialOptions[service];

    return `${obj.serviceName} ${obj.serviceValueName}`;

  }

  function getSocialAdornment(service: string): string {
    if (service.length === 0) return '';

    let obj = socialOptions[service];
    return obj.adornment;
  }

  function handleSocialValueChange(index: number, newVal: string) {
    let newSocials = [...socials];
    newSocials[index].service_identifier = newVal;
    setSocials(newSocials);
    validateSocials(newSocials);
  }

  function removeSocial(i: number) {
    if (socialOptions[socials[i].service_name]) {
      handleSocialDelete(socials[i].service_name);
    }

    let newSocials = [...socials];
    newSocials.splice(i, 1);
    setSocials(newSocials);

    let newErrors = {...errors};
    delete newErrors[`social-select-${i}`];
    delete newErrors[`social-ident-${i}`];
    setErrors(newErrors);

    if (newSocials.length === 0) {
      setSocialChange(true);
    }
  }

  function checkAddDisabled(): boolean {
    if (socials.length === 0 ) return false;
    if (socials[socials.length - 1].service_name === '') return true;

    return Object.values(socialOptions).filter((op) => !op.selected).length === 0;
  }

  function addBlankSocial() {
    let newSocials = [...socials];
    newSocials.push(new ICasterSocialInfo());
    setSocials(newSocials);
    setSocialChange(false);
  }

  function handleTextChange(newVal: string, selector: 'name' | 'sponsor_prefix' | 'pronouns') {
    let newProfile = {...profile};
    if (selector === 'name') {
      newProfile.name = newVal;
      validateProfile(newProfile);
    }
    else if (selector === 'sponsor_prefix') {
      newProfile.sponsor_prefix = newVal;
    }
    else {
      newProfile.pronouns = newVal;
    }

    setProfile(newProfile);
  }

  function toggleSharing() {
    let newProfile = {...profile};
    newProfile.sharing_enabled = !newProfile.sharing_enabled;
    setProfile(newProfile);
  }

  const [canSubmit, setCanSubmit] = useState(props.type === 'new' ? false : true);
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [socialChange, setSocialChange] = useState(props.type === 'new' ? false : true);
  const [profileChange, setProfileChange] = useState(props.type === 'new' ? false : true);

  function validateProfile(prof: CasterModel) {
    setProfileChange(true);

    let newErrors = {...errors};

    if (prof.name.trim().length === 0) {
      newErrors.name = 'Name is required';
    }

    else {
      delete newErrors.name;
    }

    if (socialChange && Object.keys(newErrors).length === 0) {
      setCanSubmit(true);
    }
    else {
      setCanSubmit(false);
    }

    setErrors(newErrors);

  }

  function validateSocials(soci: ICasterSocialInfo[], newErrors = {...errors}) {
    setSocialChange(true);

    soci.forEach((s, i) => {
      if (s.service_name === '') {
        newErrors[`social-select-${i}`] = 'Service cannot be blank';
      }

      else {
        delete newErrors[`social-select-${i}`];
      }

      if (s.service_identifier.trim() === '') {
        newErrors[`social-ident-${i}`] = 'Indetifier cannot be blank';
      }

      else {
        delete newErrors[`social-ident-${i}`];
      }

      setErrors(newErrors);

      if (profileChange && Object.keys(newErrors).length === 0) {
        setCanSubmit(true);
      }
      else {
        setCanSubmit(false);
      }
    });
  }

  const [loaderView, setLoaderView] = useState<'loading' | 'error' | 'success'>('loading');
  const [showLoader, setShowLoader] = useState(true);

  function handleSubmit() {
    setShowLoader(true);
    setLoaderView('loading');

    let newProfile = {...profile};

    newProfile.has_profile = true;

    newProfile.socials = {};

    socials.forEach(s => {
      if (s.service_name !== '') {
        newProfile.socials[s.service_name] = s;
      }
    });

    if (props.userType && props.userType === 'admin') {
      adminEditCaster(newProfile).then(() => {
        setLoaderView('success');
        setTimeout(() => {
          setShowLoader(false);
          navigate('/admin/casters');
        }, 3000);
      }).catch(() => {
        setLoaderView('error');
        setTimeout(() => {
          setShowLoader(false);
        }, 3000);
      });
    }

    else if (props.type === 'new') {
      submitNewCaster(newProfile).then(() => {
        setLoaderView('success');
        setTimeout(() => {
          setShowLoader(false);
          navigate('/caster/view');
        }, 3000);
      }).catch(() => {
        setLoaderView('error');
        setTimeout(() => {
          setShowLoader(false);
        }, 3000);
      });
    }
    else {
      editCaster(newProfile).then(() => {
        setLoaderView('success');
        setTimeout(() => {
          setShowLoader(false);
          navigate('/caster/view');
        }, 3000);
      }).catch(() => {
        setLoaderView('error');
        setTimeout(() => {
          setShowLoader(false);
        }, 3000);
      });
    }
  }

  return (
    <>
      <GlobalLoader view={loaderView} display={showLoader}/>
      <Typography style={{marginBottom: '25px'}} component="h2" variant='h6'>{title}</Typography>

      <div className='form-flex'>
        <div className='form-item'>
          <Grid container spacing={2}>
            <Grid xs={4}>
              <TextField
                label="Sponsor Prefix"
                variant="outlined"
                value={profile.sponsor_prefix}
                fullWidth
                helperText="Optional"
                onChange={(e) => handleTextChange(e.target.value as string, 'sponsor_prefix')}
              />
            </Grid>
            <Grid xs={8}>
              <TextField
                label="Preferred Name"
                variant="outlined"
                value={profile.name}
                fullWidth
                helperText={errors.name ? errors.name : "The name that will be shown on stream."}
                onChange={(e) => handleTextChange(e.target.value as string, 'name')}
                error={errors.name !== undefined}
              />
            </Grid>
          </Grid>
        </div>

        <div className='form-item'>
          <TextField
            label="Discord Tag"
            variant="filled"
            value={profile.discord_tag}
            disabled
            fullWidth
            helperText={<>Your Discord tag (autofilled).</>}
          />
        </div>

        <div className='form-item'>
          <TextField
            label="Preferred Pronouns"
            value={profile.pronouns}
            variant="outlined"
            fullWidth
            helperText="Optional"
            onChange={(e) => handleTextChange(e.target.value as string, 'pronouns')}
          />
        </div>

        

        {socials.map((social, i) => (
          <div className='form-item' key={`social-container-${i}`}>
            <Grid container spacing={2}>
              <Grid xs={4}>
                <FormControl sx={{width: '100%'}}>
                  <InputLabel id={`social-select-label-${i}`}>Select Service</InputLabel>
                  <Select
                    labelId={`social-select-label-${i}`}
                    id={`social-select-${i}`}
                    value={social.service_name}
                    label="Select Service"
                    onChange={(e) => {handleSocialSelectChange(i, e.target.value as string)}}
                    error={errors[`social-select-${i}`] !== undefined}
                  >
                  {social.service_name !== '' ? <MenuItem value={social.service_name}>{social.service_name}</MenuItem> : null}
                  {Object.values(socialOptions).filter((op) => !op.selected).map((op, j) => (
                    <MenuItem key={`social-${i}-option-${j}`} value={op.serviceName}>{op.serviceName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={7}>
              <TextField
                label={getSocialLabel(social.service_name) ?? 'Service Identifier'}
                variant="outlined"
                fullWidth
                disabled={social.service_name.length === 0}
                value={social.service_identifier}
                onChange={(e) => handleSocialValueChange(i, e.target.value as string)}
                InputProps={{ startAdornment: <InputAdornment position="start">{getSocialAdornment(social.service_name)}</InputAdornment> }}
                error={errors[`social-ident-${i}`] !== undefined}
                helperText={errors[`social-ident-${i}`] ? errors[`social-ident-${i}`] : ''}
              />
            </Grid>
            <Grid xs={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Tooltip title="Remove Social Account">
                <IconButton
                  onClick={() => removeSocial(i)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
        ))}

        <div className='form-item'>
          <div style={{display: 'flex', alignSelf: 'flex-start'}}>
            <Button
              variant='outlined'
              onClick={() => addBlankSocial()}
              disabled={checkAddDisabled()}
            >
              <AddIcon className='icon-spacing'/> Add{socials.length === 0 ? ' Social Connection' : ' Another Social Connection'}
            </Button>
          </div>
        </div>

        
        <div className='form-item'>
          <div style={{display: 'flex', alignSelf: 'flex-start'}}>
            <FormControlLabel
              control={
                <Switch
                  checked={profile.sharing_enabled}
                  onChange={() => toggleSharing()}  
                />
              }
              label={
                <>
                  Enable Profile Sharing
                  <Tooltip title='Enables other organizations to fetch your caster profile if you have provided them with your sharing key.'>
                    <IconButton>
                      <HelpIcon/>
                    </IconButton>
                  </Tooltip>
                </>
              }
              value={profile.sharing_enabled}
              />
          </div>
        </div>

        {props.type === 'new' ?
          <div className='form-item'>
            <Button
              variant='contained'
              color='primary'
              style={{width: '100%'}}
              disabled={!canSubmit || !profileChange || !socialChange}
              onClick={() => handleSubmit()}
            ><AddCircleIcon className='icon-spacing'/>Create Caster Profile</Button>
          </div>
          : null
        }

        {props.type === 'edit' ?
          <div className='form-item'>
            <Button
              variant='contained'
              color='primary'
              style={{width: '100%'}}
              disabled={!canSubmit || !profileChange || !socialChange}
              onClick={() => handleSubmit()}
            ><EditIcon className='icon-spacing'/>Edit Caster Profile</Button>
          </div>
          : null
        }
        
      </div>
    </>
  )
}

