import { Button, Divider } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function TwitchConnectPanel() {
    const navigate = useNavigate();

    let redirect_uri = 'https://hub.level1.tv/api/twitch-auth'

    if (window.location.href.includes('localhost')) {
        redirect_uri = 'http://localhost:2999/api/twitch-auth';
    }

    redirect_uri = encodeURIComponent(redirect_uri);

    const params = useSearchParams()[0];

    return (
        <div>
            <h2>Twitch Connection</h2>

            <Divider />

            {params.get('status') === 'success' && (
                <>
                    <p>Account connected successfully.</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate('.');
                        }}
                    >
                        Connect Another Account
                    </Button>
                </>
            )}

            {params.get('status') === 'error' && (
                <>
                    <p>There was an error.</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate('.');
                        }}
                    >
                        Try Again
                    </Button>
                </>
            )}

            {params.get('status') === null && (
                <>
                    <p>
                        Connect your Twitch account to Level1 Event Hub to allow Level1 to
                        display live viewer counts to our broadcast staff, as well as automatically manage channel
                        predictions.
                    </p>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            window.location.href = ('https://id.twitch.tv/oauth2/authorize?client_id=lqv7qpav2mpi5dh87dh4sz27klixeo&force_verify=true&redirect_uri=' + redirect_uri + '&response_type=code&scope=' + ['channel:manage:predictions', 'channel:read:predictions', 'channel:manage:polls', 'channel:read:polls', 'chat:read'].map(encodeURIComponent).join('+'))
                        }}
                    >
                        Connect Twitch
                    </Button>
                </>
            )}
        </div>
    );
}