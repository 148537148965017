import React, { useCallback, useEffect, useState } from 'react';

import { Button, IconButton, ToggleButtonGroup, ToggleButton, ThemeProvider, useMediaQuery, Drawer, Box, ListItem, ListItemButton, ListItemText, List, ListItemIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

import { darkTheme } from '../../MainTheme';
import { loginRequest } from '../../services/configs/MSALConfig';

import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';

import './AppHeader.css';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { hasAuthLevel } from '../../services/auth-service/hasAuthLevel';

class NavBarOption {
  value: string = '';
  label: string = '';
  level: 'admin' | 'user' | 'unauthorized';

  constructor(value: string, label: string, level: 'admin' | 'user' | 'unauthorized') {
    this.value = value;
    this.label = label;
    this.level = level;
  }
}

export function AppHeader() {
  const [currentPage, setCurrentPage] = useState('/' + window.location.pathname.split('/')[1].toLowerCase());

  window.addEventListener('hashchange', () => {
    setCurrentPage('/' + window.location.pathname.split('/')[1].toLowerCase());
  })

  const [ navBarOptions, setNavBarOptions ] = useState<NavBarOption[]>([]);

  const navigate: NavigateFunction = useNavigate();

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const [isVaxVerify, setIsVaxVerify] = useState<boolean | undefined>(undefined);
  const [isCaster, setIsCaster] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (currentPage.startsWith('/vax-verify')) {
      setIsVaxVerify(true);
    }
    else {
      setIsVaxVerify(false);
    }

    if (currentPage.startsWith('/caster')) {
      setIsCaster(true);
    }
    else {
      setIsCaster(false);
    }
  }, [currentPage]);

  const storeAuthToken = useCallback(async () => {
    if (instance.getAllAccounts().length > 0 && isAuthenticated) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      
      let res = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount() ?? instance.getAllAccounts()[0],
      }).catch((e) => { 
        console.error(e);
        return undefined;
      });

      if (res?.tokenType && res?.accessToken) {
        window.localStorage.setItem('auth', `${res?.tokenType} ${res?.accessToken}`);
        document.cookie = `lvl1_auth=${res?.tokenType} ${res?.accessToken}; path=/`;
        //window.location.reload();
      }
      else {
        console.error("Missing information for token refresh.");
        console.error(res);
        return;
      }
    }
  }, [isAuthenticated, instance]);

  useEffect(() => {
    storeAuthToken();

    setInterval(() => {
      console.log("Refreshing auth token...");
      storeAuthToken();
    }, 60000);
  },[storeAuthToken]);  

  const generateLinks = useCallback(async () => {
    let ops: NavBarOption[] = [];

    if (isVaxVerify) {
      ops.push(new NavBarOption('/vax-verify', 'Vax Verify', 'unauthorized'));
    }

    if (isCaster) {
      ops.push(new NavBarOption('/caster', 'Caster Panel', 'unauthorized'));
    }

    //Admin Panel Link
    if (instance && instance.getActiveAccount()) {
      if (isAuthenticated) {
        ops.push(new NavBarOption('/', 'Dashboard', 'user'));
      }

      let res = await hasAuthLevel(instance, isAuthenticated, 'admin').catch((e) => console.error(e));
      if (res) {
        ops.push(new NavBarOption('/admin', 'Admin Panel', 'admin'));
      }
    }

    setNavBarOptions(ops);
  }, [instance, isAuthenticated, isVaxVerify, isCaster]);

  useEffect(() => {
    generateLinks();
  }, [instance, isAuthenticated, isVaxVerify, isCaster, generateLinks])

  const location = useLocation();
  useEffect(() => {
    setCurrentPage('/' + location.pathname.split('/')[1].toLowerCase());
  }, [location])

  let handleNavChange = (newVal: string): void => {
    if (newVal) {
      setCurrentPage('/' + newVal);
      navigate(newVal);
    }
  }

  async function handleLogin(instance: any) {
    await (instance as IPublicClientApplication).loginRedirect(loginRequest).catch((e: any) => {
        console.error(e);
    });
  }

  function handleLogout(instance: any) {
    window.localStorage.removeItem('auth');
    instance.logoutRedirect().catch((e: any) => {
        console.error(e);
    });
  }

  const isMobile = useMediaQuery('(max-width: 600px)');

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div id="app-header-container">

      {(isMobile && (isAuthenticated || isVaxVerify || isCaster)) ? (
        <>
          <IconButton color="secondary" onClick={() => { setDrawerOpen(true); }}>
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor='left'
            open={drawerOpen}
            onClose={() => { setDrawerOpen(false); }}
          >

            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={() => { setDrawerOpen(false); }}
              onKeyDown={() => { setDrawerOpen(false); }}
            >

              <List>
                <ListItem>
                  <ListItemButton onClick={() => handleLogout(instance)}>
                    <ListItemIcon>
                      <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </ListItem>

                {navBarOptions.map((option: NavBarOption) => {
                  return (
                    <ListItem key={option.label}>
                      <ListItemButton onClick={() => handleNavChange(option.value)}>
                        <ListItemText primary={option.label} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>

            </Box>

          </Drawer>
        </>
      ) : (
        <></>
      )}

      {(isMobile && (!isAuthenticated && !isVaxVerify && !isCaster)) ? (
        <Button size='small' color="secondary" variant='contained' onClick={() => handleLogin(instance)}>Login</Button>
      ) : (<></>)}

      <span id="app-header-left">

        {isMobile ? (
          <a href="https://hub.level1.tv"><img id="wordmark-only" src="/logos/level1-logo-no-wordmark.png" alt="Level 1 Logo"/></a>
        ) : (
          <a href="https://hub.level1.tv"><img id="level1-logo" src="/logos/level1-logo.png" alt="Level 1 Logo"/></a>
        )}
        
        <span id="app-title">Event Hub</span>
      </span>

      {isMobile ? (
        <></>
      ) : (
        <span id="app-header-right" style={{display: 'flex', alignItems: 'center'}}>
          {isAuthenticated || isVaxVerify || isCaster ? (
            <ThemeProvider theme={darkTheme}>
              <ToggleButtonGroup
                color='secondary'
                value={currentPage}
                exclusive
                onChange={(_e, newVal: string) => handleNavChange(newVal)}>
                
                {navBarOptions.map((option: NavBarOption) => {
                  return (
                    <ToggleButton key={option.value} value={option.value}>
                      {option.label}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>

              {isAuthenticated ? (
              <IconButton style={{marginLeft: 15}} color='primary' onClick={() => handleLogout(instance)}>
                <LogoutIcon />
              </IconButton>) : (<></>)}
              
            </ThemeProvider>
          ) : (
            <Button color="secondary" variant='contained' onClick={() => handleLogin(instance)}>Login</Button>
          )}
        </span>
      )}
    
    </div>
  )
}