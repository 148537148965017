import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import { allowedGroups, loginRequest } from "../../services/configs/MSALConfig";

export function RenderIfAuthorized(props: {level: "unauthorized" | "user" | "admin", isAuthorized: boolean, children: any}) {
  const { instance } = useMsal();

  const [authLevel, setAuthLevel] = useState<'admin' | 'user' | 'unauthorized'>('unauthorized');

  let isAuthorized = false;

  useEffect(() => {
    const getAuthLevel = async () => {
      instance.setActiveAccount(instance.getAllAccounts()[0]);

      if (instance.getActiveAccount()) {
        const res = await instance.acquireTokenSilent({
          ...loginRequest,
          account: instance.getActiveAccount() ?? instance.getAllAccounts()[0],
        });

        if (!res?.idTokenClaims || !(res.idTokenClaims as any).groups) {
          if (props.isAuthorized) {
            setAuthLevel('user');
          }
        } else {
          let level: "unauthorized" | "user" | "admin" = "unauthorized";
          let groups = (res.idTokenClaims as any).groups;

          if (groups.includes(allowedGroups.adminGroup)) {
            level = "admin";
          }
          else if (isAuthorized) {
            level = 'user';
          }

          setAuthLevel(level);
        }
      }
    };
    getAuthLevel();
  }, [instance, props.isAuthorized, isAuthorized]);

  if (props.level === "admin") {
    if (authLevel === "admin") isAuthorized = true;
  } 
  else if (props.level === "user") {
    if (authLevel === "admin" || authLevel === "user") isAuthorized = true;
  }

  return (
    (isAuthorized && <>{props.children}</>) || (<></>)
  );
}
