import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { GlobalLoader } from '../../GlobalLoader';

import { useNavigate } from 'react-router-dom';
import { CasterModel } from '../../../services/types/CasterModel';
import { deleteCasterProfile, getCasterInfoFromToken } from '../../../services/api-service/ApiService';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Share from '@mui/icons-material/Share';

import './CasterOverview.css';

export function CasterOverview() {
  //Hooks
  const navigate = useNavigate();
  //const location = useLocation();

  //State
  const [profile, setProfile] = useState(new CasterModel());

  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [loaderView, setLoaderView] = useState<'success' | 'loading' | 'error'>('loading');

  const [showConf, setShowConf] = useState<boolean>(false);

  //On First Load
  useEffect(() => {
    getCasterInfoFromToken().then((res) => {
      if (res) {
        setProfile(res);
        setShowLoader(false);
      }
    }).catch((e) => {
      console.log(e);
      setLoaderView('error');
    });
  }, []);

  const [showCopy, setShowCopy] = useState(false);

  function handleCopyClick() {
    navigator.clipboard.writeText(profile.share_key);
    setShowCopy(true);
  }

  function handleDeleteClick() {
    setShowConf(false);
    setLoaderView('loading');
    setShowLoader(true);

    deleteCasterProfile().catch(() => {
      setLoaderView('error');
      setTimeout(() => {
        setShowLoader(false);
      }, 3000);
    }).then(() => {
      setLoaderView('success');
      setTimeout(() => {
        setShowLoader(false);
        navigate('/caster/new');
      }, 3000);
    })
  }

  return (
    <>
      <GlobalLoader view={loaderView} display={showLoader}/>
      <Typography style={{marginBottom: '25px'}} component="h2" variant='h6'>View Caster</Typography>

      <Dialog open={showConf} onClose={() => setShowConf(false)}>
        <DialogTitle>
          Are you sure you want to delete your profile?
        </DialogTitle>
        <DialogContent>
          <Typography>This action cannot be undone. You can create a new profile at any time.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConf(false)}>Cancel</Button>
          <Button variant='contained' onClick={() => handleDeleteClick()}><DeleteIcon className='icon-spacing'/> Delete Profile</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={showCopy} autoHideDuration={6000} onClose={() => setShowCopy(false)}>
        <Alert severity='success' sx={{width: '100%'}} onClose={() => setShowCopy(false)}>
          Share key copied to clipboard!
        </Alert>
      </Snackbar>

      <div className="main-frame">
        <div className="actions-container">
          <Tooltip title="Edit Profile">
            <IconButton className="light-button" onClick={() => navigate('/caster/edit')}>
              <EditIcon/>
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Profile">
            <IconButton onClick={() => setShowConf(true)} className="light-button">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
            
        </div>

        <div className="profile-inner">

          <div className="profile-left">
            <div className="profile-image">
              <img src={`https://cdn.discordapp.com/avatars/${profile.discord_id}/${profile.discord_avatar}.png`} alt="Profile Avatar"/>
            </div>
          </div>

          <div className="profile-right">
            <div className="display-name-container">
              {profile.sponsor_prefix && profile.sponsor_prefix.length > 0 ? <span className="sponsor-tag">{profile.sponsor_prefix}</span> : null}
              <span className="display-name">{profile.name}</span>
            </div>

            {profile.pronouns && profile.pronouns.length > 0 ?
              <div className="pronoun-container">
                <span>{profile.pronouns}</span>
              </div>
              : null}
          </div>

        </div>
      </div>

      <div className='additional-info'>
        {profile.sharing_enabled && profile.share_key && profile.share_key.length > 0 ?
          <Tooltip title="Click to copy share key.">
            <TextField
              label='Share Key'
              variant='filled'
              disabled={true}
              className='light-button'
              onClick={() => handleCopyClick()}
              value={profile.share_key}
              InputProps={{
                startAdornment: <InputAdornment position='start'><Share/></InputAdornment>
              }}
            />
          </Tooltip>
        : null}

        {Object.values(profile.socials).length > 0 ? <div>

          <Typography>Social Info</Typography>
          
          <TableContainer className="caster-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>Service Identifier</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.values(profile.socials).map((s, i) => (
                  <TableRow style={ i % 2? { background : "#e1edec" }:{ background : "white" }}  key={`social-row-${i}`}>
                    <TableCell>
                      {s.service_name}
                    </TableCell>
                    <TableCell>
                      {s.service_identifier}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div> : null}
        
      </div>
    </>
  )
}