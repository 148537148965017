export class TransactionModel {
    id?: number;
    amount: number = 0.00;
    date: Date | undefined = undefined;
    description: string ="";
    vendor: string = "";
    type?: 'Income' | 'Expense';
    category: string = "";
    documenturl?: string;
    creatorname?: string;
    creatorid?: string;
    eventid?: string;
}
