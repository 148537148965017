import React, { useState, useEffect } from 'react';

import { Alert, Badge, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { GlobalLoader } from '../../GlobalLoader';
import { checkNodeCGOnline, getNodeCGGraphics, getNodeCGPanels } from '../../../services/api-service/ApiService';
import { useNavigate } from 'react-router-dom';

// Icon Imports
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import TvIcon from '@mui/icons-material/Tv';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import { GraphicItem } from '../GraphicItem';
import dayjs from 'dayjs';

class ConnectionStatus {
  online: boolean;
  lastUpdated: Date;

  constructor(online: boolean) {
    this.online = online;
    this.lastUpdated = new Date();
  }
}

interface DashboardFrameProps {
  id: string;
}

class Panel {
  url: string = "";
  title: string = "";

  constructor(title: string, url: string) {
    this.url = url;
    this.title = title;
  }
}

class Graphic {
  url: string = "";
  width: number = 0;
  height: number = 0;
}

export function DashboardFrame(props: DashboardFrameProps) {
  const navigate = useNavigate();

  const heightOffset = 148;
  const [height, setHeight] = useState(window.innerHeight - heightOffset);

  const [panels, setPanels] = useState<Panel[]>([]);
  const [graphics, setGraphics] = useState<Graphic[]>([]);

  const [errorDialog, setErrorDialog] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [loaderView, setLoaderView] = useState<'loading' | 'success' | 'error'>('loading');

  const [showGraphics, setShowGraphics] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight - heightOffset);
    })

    document.addEventListener('keydown', () => { setFullscreen(false); }, false);
  });

  function storeDashCookie() {
    document.cookie = `dash_id=${props.id}; path=/`;
  }

  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(new ConnectionStatus(true));

  useEffect(() => {
    storeDashCookie();

    setShowLoader(true);
    setLoaderView('loading');
    setErrorDialog(false);

    // TODO: Fix error handling. All resolutions are errors currently.
    checkNodeCGOnline(props.id).then(() => { console.log("RESOLVED"); }).catch(() => {
      setErrorDialog(true);
      setConnectionStatus(new ConnectionStatus(true));
    });

    getNodeCGPanels(props.id).then((panels: Panel[]) => {
      setPanels(panels);
      setShowLoader(false);
    }).catch(() => {
      setLoaderView('error');
      setShowLoader(true);
    });

    getNodeCGGraphics(props.id).then((graphics: Graphic[]) => {
      setGraphics(graphics);
    }).catch(() => {
      setLoaderView('error');
      setShowLoader(true);
    });

    checkCookieStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, setValue] = useState(0);

  const [statusDialog, setStatusDialog] = useState(false);

  function checkNodeCGConnection() {
    checkNodeCGOnline(props.id).then(() => {
      setConnectionStatus(new ConnectionStatus(true));
    }).catch(() => {
      setConnectionStatus(new ConnectionStatus(false));
    });
  }

  const [interval, setStoredInterval] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    clearInterval(interval);    

    setStoredInterval(setInterval(() => {
      checkNodeCGConnection();
    }, 60000));

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatTime(lastUpdated: Date): string {
    return dayjs(lastUpdated).format('h:mm a');
  }  

  const [cookiesEnabled, setCookiesEnabled] = useState<boolean>(true);
  
  function checkCookieStatus() {
    setCookiesEnabled(navigator.cookieEnabled);
  }

  const [fullscreen, setFullscreen] = useState(false);

  
  return (
    <div>

    <Button sx={{ display: fullscreen ? 'block' : 'none', zIndex: 10000, position: 'absolute', top: '20px', right: '20px' }} size='small' variant='contained' onClick={() => { setFullscreen(false); }}>
      Exit Fullscreen
    </Button>

    <GlobalLoader view={loaderView} display={showLoader}/>

    <Dialog open={!cookiesEnabled}>
      <DialogTitle>Enable Cookies</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Cookies are required for this application to function. Please enable cookies in your browser settings then refresh this tab.
          </DialogContentText>
      </DialogContent>
    </Dialog>

    <Dialog open={statusDialog} onClose={() => { setStatusDialog(false); }}>
      <DialogTitle>Status</DialogTitle>
      <DialogContent>

        <Stack spacing={2} direction={'column'}>
          {connectionStatus.online ? (
            <Alert severity='success'>Connected to NodeCG.</Alert>
          ) : (
            <Alert severity='error'>There was an error connecting to NodeCG.</Alert>
          )}

          <Typography variant='body2' color="text.secondary" align='left'>
            Last Updated: {formatTime(connectionStatus.lastUpdated)}
          </Typography>
        </Stack>
        
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => { setStatusDialog(false); }}><CloseIcon className='icon-spacing'/>Close</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={errorDialog}>
      <DialogTitle>Error connecting to dashboard.</DialogTitle>
      <DialogContent>
        <DialogContentText>There was an error connecting to the dashboard for {props.id}. Please try again later or contact <code>Devon#0005</code> on Discord for assistance.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => { navigate('/') }}>Exit</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={showGraphics} onClose={() => { setShowGraphics(false); }}>
      <DialogTitle>Graphics For {props.id}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} direction={'column'}>
          {graphics.map((g, i) => {
            return (
              <GraphicItem graphic={g} key={`graphic-item-${i}`}/>
            )
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => { setShowGraphics(false); }}><CloseIcon className='icon-spacing'/>Close</Button>
      </DialogActions>
    </Dialog>

    <Stack direction='row' spacing={2}>
      <Tabs value={value} onChange={(_e, newVal) => { setValue(newVal) }}>
        {panels.map((panel, index) => {
          return (
            <Tab label={panel.title} key={index} />
          )
        })}
      </Tabs>

      <Tooltip title="Open Graphics Menu">
        <IconButton disabled={!(graphics.length > 0)} onClick={() => { setShowGraphics(true); }}><TvIcon/></IconButton>
      </Tooltip>

      <Tooltip title="Open Status Menu">
        
        <IconButton onClick={() => { setStatusDialog(true); }}>
          <Badge invisible={fullscreen} variant='dot' sx={{"& .MuiBadge-badge": { backgroundColor: connectionStatus.online ? "#4caf50" : "#ff9528" }}} >
            <SettingsEthernetIcon/>
          </Badge>
        </IconButton>
        
      </Tooltip>

      <Tooltip title="Fullscreen This Panel">
        
        <IconButton onClick={() => { setFullscreen(true); }}>
          <FullscreenIcon/>
        </IconButton>
        
      </Tooltip>
    </Stack>
    
    {panels.map((panel, index) => {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          key={`panel-${index}`}
          id={`panel-${index}`}
          style={{
            position: fullscreen ? 'absolute' : 'static',
            top: 0,
            height: fullscreen ? '100vh' : 'auto',
            width: fullscreen ? '100vw' : 'auto',
          }}
        >
          <iframe
            title={panel.title}
            src={panel.url}
            scrolling='auto'
            frameBorder={0}
            height={fullscreen ? '100%' : height}
            style={{
              display: 'block'
            }}
            width={'100%'}
          />
        </div>
      )
    })}

      
    </div>
  )
}