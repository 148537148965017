import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { DashboardFrame } from './DashboardFrame';

export function DashboardHandler() {
  const { dash } = useParams();

  return (
    <Routes>
      <Route path="view" element={<DashboardFrame id={dash ?? ''}/>}/>
    </Routes>
  )
}