import { useIsAuthenticated } from '@azure/msal-react';
import { Delete } from '@mui/icons-material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Button, Card, CardActions, CardContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { deleteExpense, getExpenses } from '../../../services/api-service/ApiService';
import { TransactionModel } from '../../../services/types/TransactionModel';
import { RenderIfAuthorized } from '../../RenderIfAuthorized';

import { Dialog } from '@mui/material';
import { GlobalLoader } from '../../GlobalLoader';


let path = window.location.pathname;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let token = window.localStorage.getItem("auth");

export function ViewExpenses() {
    const isAuthenticated = useIsAuthenticated();
    const [eventId] = useState(path.split('/')[2]);

    const [expenses, setExpenses] = useState<any>({
        income: [],
        expense: [],
        incomeTotal: '$0.00',
        expenseTotal: '$0.00',
    });

    useEffect(() => {
        getExpenses(eventId).then((result) => {
            if (!result.expenseTotal) {
                result.expenseTotal = '$0.00';
            }
            if (!result.incomeTotal) {
                result.incomeTotal = '$0.00';
            }
            
            setExpenses(result);
        }).catch((error) => {
            console.error(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function formatDate(date: string): string {
        let noTime = date.split('T')[0];
        let arr = noTime.split('-');
        let year = arr[0];
        let month = arr[1];
        let day = arr[2];

        return `${month}/${day}/${year}`;
    }

    function formatProfit(): string {
        let income = 0;
        let expense = 0;

        if (expenses.incomeTotal) {
            income = parseFloat(expenses.incomeTotal.replace('$', '').replace(',', ''));
        }
        if (expenses.expenseTotal) {
            expense = parseFloat(expenses.expenseTotal.replace('$', '').replace(',', ''));
        }

        let profit = income - expense;

        let isLoss = profit < 0;

        profit = Math.abs(profit);

        let profitString = profit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        profitString = `$${profitString}`;

        if (isLoss) {
            profitString = `(${profitString})`;
        }

        return profitString;
    }

    function refreshExpenses() {
        getExpenses(eventId).then((result) => {
            setExpenses(result);
        }).catch((error) => {
            console.error(error);
        });
    }

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [deleteTrans, setDeleteTrans] = useState<TransactionModel>(new TransactionModel());

    function handleDeleteClick(tr: TransactionModel) {
        setDeleteDialog(true);
        setDeleteTrans(tr);
        setDeleteId((tr.id?.toString()) ?? '');
    }

    const [displayLoader, setDisplayLoader] = useState(false);
    const [loaderView, setLoaderView] = useState<'loading' | 'error' | 'success'>('loading');

    function handleDeleteTransaction() {
        setDeleteDialog(false);
        setLoaderView('loading');
        setDisplayLoader(true);

        deleteExpense(deleteId).then(() => {
            setLoaderView('success');
            refreshExpenses();
            setTimeout(() => {
                setDisplayLoader(false);
            }, 3000);

            let oldExp = JSON.parse(JSON.stringify(expenses));
            let i = -1;
            oldExp[deleteTrans.type?.toLowerCase() ?? ''].forEach((tr: TransactionModel, index: number) => {
                if ((tr.id ?? -1).toString() === deleteId) {
                    i = index;
                }
            });

            if (i !== -1) {
                oldExp[deleteTrans.type?.toLowerCase() ?? ''].splice(i, 1);
                setExpenses(oldExp);
            }
        }).catch((err) => {
            console.error(err);
            setLoaderView('error');
            setTimeout(() => {
                setDisplayLoader(false);
            }, 3000);
        }).finally(() => {
            setDeleteId('');
            setDeleteTrans(new TransactionModel());
        });
    }

    return(
        <RenderIfAuthorized level='admin' isAuthorized={isAuthenticated}>
            <GlobalLoader display={displayLoader} view={loaderView}></GlobalLoader>
            <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)} maxWidth='sm'>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Delete Transaction {deleteId}? This action cannot be undone.
                        </Typography>
                        <Typography variant="body2">Vendor: {deleteTrans.vendor}</Typography>
                        <Typography variant="body2">Description: {deleteTrans.description}</Typography>
                        <Typography variant="h6">Description: {deleteTrans.amount}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant='text' onClick={() => setDeleteDialog(false)}>Go Back</Button>
                        <Button variant='contained' onClick={() => handleDeleteTransaction()}>Delete Transaction</Button>
                    </CardActions>
                </Card>
            </Dialog>
            <Card>
                <CardContent>
                    <Typography variant='h5'>Income For {eventId}</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Creator</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Vendor</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Doc</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {expenses.income.map((tr: TransactionModel, i: number) => (
                                    <TableRow style={ i % 2? { background : "#e1edec" }:{ background : "white" }} key={tr.id} >
                                        <TableCell>{tr.id}</TableCell>
                                        <TableCell>{formatDate(tr.date as any)}</TableCell>
                                        <TableCell>{tr.creatorname}</TableCell>
                                        <TableCell>{tr.category}</TableCell>
                                        <TableCell>{tr.vendor}</TableCell>
                                        <TableCell>{tr.description}</TableCell>
                                        <TableCell><IconButton onClick={() => window.open(`/api/expenses/documents/${tr.documenturl}?token=${token}`, '_blank')}><FileCopyIcon/></IconButton></TableCell>
                                        <TableCell>{tr.amount}</TableCell>
                                        <TableCell><IconButton onClick={() => handleDeleteClick(tr)}><Delete/></IconButton></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant='h4' textAlign={'right'}>{expenses.incomeTotal}</Typography>
                </CardContent>
            </Card>

            <Card>
                <CardContent>
                    <Typography variant='h5'>Expenses For {eventId}</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Creator</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Vendor</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Doc</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {expenses.expense.map((tr: TransactionModel, i: number) => (
                                    <TableRow style={ i % 2? { background : "#e1edec" }:{ background : "white" }} key={tr.id} >
                                        <TableCell>{tr.id}</TableCell>
                                        <TableCell>{formatDate(tr.date as any)}</TableCell>
                                        <TableCell>{tr.creatorname}</TableCell>
                                        <TableCell>{tr.category}</TableCell>
                                        <TableCell>{tr.vendor}</TableCell>
                                        <TableCell>{tr.description}</TableCell>
                                        <TableCell><IconButton onClick={() => window.open(`/api/expenses/documents/${tr.documenturl}?token=${token}`, '_blank')}><FileCopyIcon/></IconButton></TableCell>
                                        <TableCell>{tr.amount}</TableCell>
                                        <TableCell><IconButton onClick={() => handleDeleteClick(tr)}><Delete/></IconButton></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant='h4' textAlign={'right'}>{expenses.expenseTotal}</Typography>
                </CardContent>
            </Card>

            <Card>
                <CardContent>
                    <Typography variant='h5'>Profit / (Loss) For {eventId}</Typography>
                    <Typography variant='h4' textAlign={'right'}>{formatProfit()}</Typography>
                </CardContent>
            </Card>
        </RenderIfAuthorized>
    );
}