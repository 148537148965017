import React, { useState } from 'react';

import { Button, Card, CardContent, CardActions, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

import { AddDashboard } from './AddDashboard';

import './AdminPanel.css';
import { EditDashboard } from './EditDashboard';
import { RenderIfAuthorized } from '../RenderIfAuthorized';
import { useIsAuthenticated } from '@azure/msal-react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CasterAdmin } from '../CasterPanel/CasterAdmin';

function AdminMenu() {
  const [addDashboardIsOpen, setAddDashboardIsOpen] = useState(false);
  const [editDashboardIsOpen, setEditDashboardIsOpen] = useState(false);

  function closeAddDashboard() {
    setAddDashboardIsOpen(false);
  }

  function closeEditDashboard() {
    setEditDashboardIsOpen(false);
  }

  const navigate = useNavigate();

  return (
    <div id="admin-panel-container">
      <div id="admin-panel-flex">
        <Card className='flex-card'>
          <CardContent>
            <Typography component="h2">
              Add Dashboard
            </Typography>
            <Typography variant="body2" component="p">
              Add a new graphics dashboard.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => setAddDashboardIsOpen(true)}>Open <LaunchIcon/></Button>
          </CardActions>
          <AddDashboard open={addDashboardIsOpen} onClose={closeAddDashboard}/>
        </Card>

        <Card className='flex-card'>
          <CardContent>
            <Typography component="h2">
              View/Edit Dashboards
            </Typography>
            <Typography variant="body2" component="p">
              View, edit, and delete existing graphics dashboards.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => setEditDashboardIsOpen(true)}>Open <LaunchIcon/></Button>
          </CardActions>
          <EditDashboard open={editDashboardIsOpen} onClose={closeEditDashboard}/>
        </Card>

        <Card className='flex-card'>
          <CardContent>
            <Typography component="h2">
              Manage Casters
            </Typography>
            <Typography variant="body2" component="p">
              Add, edit, delete, and assign casters to events.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => navigate('/admin/casters')}>Open <LaunchIcon/></Button>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}

export function AdminPanel() {
  let isAuthenticated = useIsAuthenticated();

  return (
    <RenderIfAuthorized level='admin' isAuthorized={isAuthenticated}>
      <h2>Admin Panel</h2>
      <Routes>
        <Route path="/" element={<AdminMenu/>}/>
        <Route path="/casters/*" element={<CasterAdmin/>}/>
      </Routes>
    </RenderIfAuthorized>
  )
}