import React, { useState } from 'react';

import { Button, Card, CardContent, CardActions, Dialog, DialogTitle, Stack, TextField, Switch, FormControlLabel, Typography, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';

import { GlobalLoader } from '../../GlobalLoader';

import { DashboardModel } from '../../../services/types/DashboardModel';

import { addDashboard, uploadDashboardCover } from '../../../services/api-service/ApiService';

export interface AddDashboardProps {
    onClose: () => void;
    open: boolean;
  }
  
  export function AddDashboard(props: AddDashboardProps) {
    const { onClose, open } = props;
  
    function closeAddDashboard() {
      onClose();
    }
  
    let [newDashboard, setNewDashboard] = useState(new DashboardModel());
  
    class FieldErrors {
      ID?: boolean = undefined;
      title?: boolean = undefined;
      game?: boolean = undefined;
      url?: boolean = undefined;
    }
  
    const defaultFieldErrors: FieldErrors = new FieldErrors();
    const [fieldErrors, setFieldErrors] = useState(defaultFieldErrors);
    
    const [canSubmit, setCanSubmit] = useState(false);
  
    function handleClose() {
      setNewDashboard(new DashboardModel());
      setFieldErrors(defaultFieldErrors);
      handleFileClear();
      onClose();
    }
  
    function handleIdChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      const VALID_REGEX: RegExp = /^[A-Z0-9_]+$/;
      let dash = JSON.parse(JSON.stringify(newDashboard));
      dash.id = event.target.value;
      setNewDashboard(dash);
  
      let errors = fieldErrors;
  
      if (!event.target.value.match(VALID_REGEX)) {
        errors.ID = true;
        setFieldErrors(errors);
      }
      else {
        errors.ID = false;
        setFieldErrors(errors);
      }
  
      checkAdd();
    }
  
    function handleUrlChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      const VALID_REGEX: RegExp = /^https?:\/\/[A-Za-z0-9]+\.level1\.tv:?[0-9]*$/;
      let dash = JSON.parse(JSON.stringify(newDashboard));
      dash.url = event.target.value;
      setNewDashboard(dash);
  
      let errors = fieldErrors;

      if (!event.target.value || event.target.value.length === 0) {
        errors.url = false;
        setFieldErrors(errors);
      }
  
      else if (!event.target.value.match(VALID_REGEX)) {
        errors.url = true;
        setFieldErrors(errors);
      }

      else {
        errors.url = false;
        setFieldErrors(errors);
      }
  
      checkAdd();
    }
  
    function handleDashPropertyChange(property: 'ID' | 'title' | 'game' | 'adminonly' | 'expenseenabled', value: any) {
      let dash = JSON.parse(JSON.stringify(newDashboard));
      dash[property] = value;
      setNewDashboard(dash);
  
      if (property !== 'adminonly' && property !== 'expenseenabled') {
        if (value.length > 0) {
          let errors = fieldErrors;
          errors[property] = false;
          setFieldErrors(errors);
        }
        else {
          let errors = fieldErrors;
          errors[property] = true;
          setFieldErrors(errors);
        }
      }
      checkAdd();
    }
  
    function checkAdd() {
      let canSubmit = true;
      Object.values(fieldErrors).forEach((value: boolean) => {
        if (value === undefined || value === true) {
          canSubmit = false;
        }
      });
  
      setCanSubmit(canSubmit);
    }
  
    const [loader, setLoader] = useState(false);
  
    async function handleDashAdd() {
      setLoader(true);

      await addDashboard(newDashboard).catch((e) => {console.error(e)});
      await uploadDashboardCover(newDashboard.id, (file as any)).catch((e) => {console.error(e)});

      setLoader(false);
      handleClose();
    }

    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(undefined);

    function handleUploadClick(event: any) {
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }

    function handleFileClear() {
      setFile(undefined);
      setFileName('');
    }
  
    return(
      <Dialog open={open} onClose={handleClose}>
        <GlobalLoader display={loader} view={'loading'}/>
        <DialogTitle>Add Dashboard</DialogTitle>
        <Card>
          <CardContent>
            <Stack spacing={4}>
              <TextField
                variant='outlined'
                label='Event ID'
                error={fieldErrors.ID}
                helperText={"Event ID must be unique and only contain capital letters, numbers, and underscores"}
                onChange={handleIdChange}
              />
              <TextField
                variant='outlined'
                label='Event Name'
                error={fieldErrors.title}
                onChange={(event) => handleDashPropertyChange("title", event.target.value)}
              />
              <TextField
                variant='outlined'
                label='Game'
                error={fieldErrors.game}
                onChange={(event) => handleDashPropertyChange("game", event.target.value)}
              />
              <TextField
                variant='outlined'
                label='Dashboard Link'
                error={fieldErrors.url}
                helperText={"[Optional] URL must be in the format http(s)://<domain>.level1.tv[:PORT]"}
                onChange={handleUrlChange}
              />

              <Stack direction={'row'} alignItems='center'>
                <Button variant='outlined' component='label' style={{maxWidth: '50%'}}>
                  <UploadFileIcon/>
                  Upload Event Logo
                  <input type={'file'} accept="image/png, image/jpeg" onChange={handleUploadClick} hidden/>
                </Button>
                <IconButton disabled={fileName.length === 0} onClick={() => handleFileClear()}>
                  <CloseIcon/>
                </IconButton>
                <Typography variant='body1'>{fileName}</Typography>
              </Stack>

              <Stack direction={'row'}>
                <FormControlLabel
                  control={<Switch
                              defaultChecked
                              onChange={(event) => handleDashPropertyChange("adminonly", event.target.checked)}
                            />}
                  label={'Admin Only'}/>
                  <FormControlLabel
                  control={<Switch
                              onChange={(event) => handleDashPropertyChange("expenseenabled", event.target.checked)}
                            />}
                  label={'Enable Expenses'}/>
              </Stack>
            </Stack>
          </CardContent>
          <CardActions>
            <Button onClick={closeAddDashboard}>Cancel</Button>
            <Button variant='contained' disabled={!canSubmit} onClick={() => handleDashAdd()}>Add<AddCircleIcon/></Button>
          </CardActions>
        </Card>
      </Dialog>
    );
  }