import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import { AppHeader } from './components/AppHeader';
import { Dashboard } from './components/Dashboard';
import { AdminPanel } from './components/AdminPanel';

import { mainTheme } from './MainTheme';
import { useIsAuthenticated } from '@azure/msal-react';
import { RenderIfAuthorized } from './components/RenderIfAuthorized';
import { ExpensePanel } from './components/ExpensePanel';
import { CasterPanel } from './components/CasterPanel';
import { DashboardHandler } from './components/DashboardHandlers';
import { TwitchConnectPanel } from './components/TwitchConnectPanel';

function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <ThemeProvider theme={mainTheme}>
      <BrowserRouter>      
        <div className="App">
          <link rel="stylesheet" href="./App.css"></link>
          <AppHeader />
          {isAuthenticated ? (
            <>
              <RenderIfAuthorized level='user' isAuthorized={isAuthenticated}>
                <Routes>
                  <Route path="/" element={<Dashboard/>}/>
                  <Route path="/admin/*" element={<AdminPanel/>}/>
                  <Route path="/expenses/*" element={<ExpensePanel/>}/>
                  <Route path="/dashboard/:dash/*" element={<DashboardHandler/>}/>
                </Routes>
              </RenderIfAuthorized>
            </>
          ) : (
            <></>
          )}

          {!isAuthenticated && !window.location.pathname.toLowerCase().startsWith('/vax-verify') && !window.location.pathname.toLowerCase().startsWith('/caster') && !window.location.pathname.toLowerCase().startsWith('/twitch-connect') ? (<h2>Please login to use this application.</h2>)
          :
          (
            <Routes>
              <Route path="/caster/*" element={<CasterPanel/>}/>
              <Route path="/twitch-connect" element={<TwitchConnectPanel/>}/>
            </Routes>
          )}

        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
