export class DashboardModel {
    id: string = '';
    title: string = '';
    game: string = '';
    iconurl: string = '';
    url: string = '';
    adminonly: boolean = true;
    expenseenabled: boolean = false;
    twitch_channel_id?: number;
}
