let redirectUri = 'https://hub.level1.tv';
if (window.location.hostname === 'localhost') {
    redirectUri = 'http://localhost:2999';
}

export const MSAL_CONFIG = {
    auth: {
        clientId: '2c55d579-3832-4947-93c0-31427bcbca15',
        authority: 'https://login.microsoftonline.com/5cdf8977-177d-4f20-933c-f965276f903e',
        redirectUri: redirectUri,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: ['User.Read'],
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const allowedGroups = {
    adminGroup: "d615be66-d17d-49fb-b106-bf3f1cbc8fba",
}
